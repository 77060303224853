const state = {
  regionList: []
}

const mutations = {
  setRegionList: function (state, regionList) {
    state.regionList = regionList
  }
}

export default {
  namespaced: true,
  state,
  mutations
}