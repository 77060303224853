const regionList = [
  {
    name: '广州乱斗堂',
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
    description: '广州地区组织',
    regionId: 1
  },
  {
    name: '深圳Smash',
    logo: 'http://image.cnsmash.com/region/深圳.png',
    description: '深圳地区组织',
    regionId: 4
  },
  {
    name: '鹭岛乱斗社',
    logo: 'http://image.cnsmash.com/region/厦门.png',
    description: '厦门地区组织',
    regionId: 2
  }
]

export default {
  'get|http://mock.test/region/list': regionList,
}