import axios from 'axios'

let src = ''
if (document.domain == 'cspr.cnsmash.com') { // 服务器
  src = 'http://api.cnsmash.com'
} else if (document.domain == 'cspr2.cnsmash.com' || document.domain == 'www.cnsmash.com') {
  src = 'http://api3.cnsmash.com/cspr'
} else {
  // src = 'http://mock.test' // mock数据
  src = 'http://localhost:9001/cspr' // spring项目
  // src = 'http://api3.cnsmash.com/cspr'
  // src = 'http://192.168.0.106:9001/cspr' // ip访问
}
axios.defaults.baseURL = src

const request = axios.create({
  timeout: 60000
})

// create an axios instance
const service = axios.create({
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    /*
    if (store.getters.token) {
      config.headers['token'] = getToken()
    }
    */
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    const code = res.code

    if (code !== 200) {
      Promise.reject(new Error(res.message || '网络错误'))
      return res
    } else {
      return res
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export {request, service, src}
