let calendar2021mar = [
  {
    year: 2021,
    month: 2,
    day: 28,
    date: '2021-02-28',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 1,
    date: '2021-03-01',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 2,
    date: '2021-03-02',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 3,
    date: '2021-03-03',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 4,
    date: '2021-03-04',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 5,
    date: '2021-03-05',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 6,
    date: '2021-03-06',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 7,
    date: '2021-03-07',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 8,
    date: '2021-03-08',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 9,
    date: '2021-03-09',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 10,
    date: '2021-03-10',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 11,
    date: '2021-03-11',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 12,
    date: '2021-03-12',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 13,
    date: '2021-03-13',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 14,
    date: '2021-03-14',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 15,
    date: '2021-03-15',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 16,
    date: '2021-03-16',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 17,
    date: '2021-03-17',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 18,
    date: '2021-03-18',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 19,
    date: '2021-03-19',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 20,
    date: '2021-03-20',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 21,
    date: '2021-03-21',
    holiday: true,
    tournament: [
      {
        logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
        name: '广州月赛',
        online: false,
        cspr: true
      }
    ]
  },
  {
    year: 2021,
    month: 3,
    day: 22,
    date: '2021-03-22',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 23,
    date: '2021-03-23',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 24,
    date: '2021-03-24',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 25,
    date: '2021-03-25',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 26,
    date: '2021-03-26',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 27,
    date: '2021-03-27',
    holiday: true,
    tournament: [
      {
        logo: 'http://image.cnsmash.com/region/厦门.png',
        name: '逐鹭杯',
        online: false,
        cspr: true
      }
    ]
  },
  {
    year: 2021,
    month: 3,
    day: 28,
    date: '2021-03-28',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 29,
    date: '2021-03-29',
    holiday: false,
    tournament: [
      {
        logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
        name: '鼎上战争 九州#3',
        online: true,
        cspr: false,
        players: 2
      }
    ]
  },
  {
    year: 2021,
    month: 3,
    day: 30,
    date: '2021-03-30',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 31,
    date: '2021-03-31',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 1,
    date: '2021-04-01',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 2,
    date: '2021-04-02',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 3,
    date: '2021-04-03',
    holiday: true,
    tournament: [
      {
        logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
        name: '广州周赛',
        online: false,
        cspr: true
      }
    ]
  },
]

let calendar2021apr = [
  {
    year: 2021,
    month: 3,
    day: 28,
    date: '2021-03-28',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 29,
    date: '2021-03-29',
    holiday: false,
    tournament: [
      {
        logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
        name: '鼎上战争 九州#3',
        online: true,
        cspr: false
      }
    ]
  },
  {
    year: 2021,
    month: 3,
    day: 30,
    date: '2021-03-30',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 3,
    day: 31,
    date: '2021-03-31',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 1,
    date: '2021-04-01',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 2,
    date: '2021-04-02',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 3,
    date: '2021-04-03',
    holiday: true,
    tournament: [
      {
        logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
        name: '广州周赛',
        online: false,
        cspr: false
      }
    ]
  },
  {
    year: 2021,
    month: 4,
    day: 4,
    date: '2021-04-04',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 5,
    date: '2021-04-05',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 6,
    date: '2021-04-06',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 7,
    date: '2021-04-07',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 8,
    date: '2021-04-08',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 9,
    date: '2021-04-09',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 10,
    date: '2021-04-10',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 11,
    date: '2021-04-1',
    holiday: true,    
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 12,
    date: '2021-04-12',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 13,
    date: '2021-04-13',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 14,
    date: '2021-04-14',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 15,
    date: '2021-04-15',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 16,
    date: '2021-04-16',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 17,
    date: '2021-04-17',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 18,
    date: '2021-04-18',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 19,
    date: '2021-04-19',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 20,
    date: '2021-04-20',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 21,
    date: '2021-04-21',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 22,
    date: '2021-04-22',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 23,
    date: '2021-04-23',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 24,
    date: '2021-04-24',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 25,
    date: '2021-04-25',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 26,
    date: '2021-04-26',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 27,
    date: '2021-04-27',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 28,
    date: '2021-04-28',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 29,
    date: '2021-04-29',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 30,
    date: '2021-04-30',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 1,
    date: '2021-05-01',
    holiday: true,
    tournament: []
  },
]

const calendar2021may = [
  {
    year: 2021,
    month: 4,
    day: 25,
    date: '2021-04-25',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 26,
    date: '2021-04-26',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 27,
    date: '2021-04-27',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 28,
    date: '2021-04-28',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 29,
    date: '2021-04-29',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 4,
    day: 30,
    date: '2021-04-30',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 1,
    date: '2021-05-01',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 2,
    date: '2021-05-02',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 3,
    date: '2021-05-03',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 4,
    date: '2021-05-04',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 5,
    date: '2021-05-05',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 6,
    date: '2021-05-06',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 7,
    date: '2021-05-07',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 8,
    date: '2021-05-08',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 9,
    date: '2021-05-09',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 10,
    date: '2021-05-10',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 11,
    date: '2021-05-11',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 12,
    date: '2021-05-12',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 13,
    date: '2021-05-13',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 14,
    date: '2021-05-14',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 15,
    date: '2021-05-15',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 16,
    date: '2021-05-16',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 17,
    date: '2021-05-17',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 18,
    date: '2021-05-18',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 19,
    date: '2021-05-19',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 20,
    date: '2021-05-20',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 21,
    date: '2021-05-21',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 22,
    date: '2021-05-22',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 23,
    date: '2021-05-23',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 24,
    date: '2021-05-24',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 25,
    date: '2021-05-25',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 26,
    date: '2021-05-26',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 27,
    date: '2021-05-27',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 28,
    date: '2021-05-28',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 29,
    date: '2021-05-29',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 30,
    date: '2021-05-30',
    holiday: true,
    tournament: []
  },
  {
    year: 2021,
    month: 5,
    day: 31,
    date: '2021-05-31',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 6,
    day: 1,
    date: '2021-06-01',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 6,
    day: 2,
    date: '2021-06-02',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 6,
    day: 3,
    date: '2021-06-03',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 6,
    day: 4,
    date: '2021-06-04',
    holiday: false,
    tournament: []
  },
  {
    year: 2021,
    month: 6,
    day: 5,
    date: '2021-06-05',
    holiday: true,
    tournament: []
  },
]

export default {
  'get|http://mock.test/calendar/month/2021/3': calendar2021mar,
  'get|http://mock.test/calendar/month/2021/4': calendar2021apr,
  'get|http://mock.test/calendar/month/2021/5': calendar2021may
}