const stageFileName = [
  {
    name: 'Battlefield',
    file: 'battlefield'
  },
  {
    name: 'Town and City',
    file: 'city'
  },
  {
    name: 'Smashville',
    file: 'village'
  },
  {
    name: 'Final Destination',
    file: 'end'
  },
  {
    name: 'Pokémon Stadium 2',
    file: 'stadium'
  },
  {
    name: 'Kalos Pokémon League',
    file: 'kalos'
  },
  {
    name: 'Yoshi\'s Story',
    file: 'cartboard'
  },
  {
    name: 'Lylat Cruise',
    file: 'lylatcruise'
  },
  {
    name: 'Yoshi\'s Island',
    file: 'island'
  },
  {
    name: 'Small Battlefield',
    file: 'battlefields'
  }
]

export default stageFileName