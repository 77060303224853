<template>
  <img :src="getSrc" :style="{width: width, opacity: opacity}"/>
</template>

<script>
import CustomIcon from './CustomIcon.js'
export default {
  ...CustomIcon
}
</script>

<style scoped>

</style>