import { render, staticRenderFns } from "./DiamondIcon.vue?vue&type=template&id=e328763c&scoped=true"
import script from "./DiamondIcon.vue?vue&type=script&lang=js"
export * from "./DiamondIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e328763c",
  null
  
)

export default component.exports