<template>
  <div class="page-layout">
    <!-- 顶部菜单栏 -->
    <div class="page-header">
      <common-header />
    </div>
    
    <!-- 页面内容 -->
    <div class="page-content">
      <router-view />
    </div>

    <!-- 页脚 -->
    <div class="page-footer">
      <common-footer />
    </div>

  </div>
</template>

<script>
import CommonHeader from '@/layout/CommonHeader/CommonHeader.vue'
import CommonFooter from '@/layout/CommonFooter/CommonFooter.vue'
export default {
  components: { CommonHeader, CommonFooter }
}
</script>

<style lang="less" scoped>
.page-layout {
  width: 100%;
  height: 100%;
  .page-header {
    height: 64px;
    width: 100%;
    padding: 0;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
  }
  .page-content {
    background-color: white;
    @media screen and (min-height: 750px) {
      padding-top: 64px;
      min-height: 800px;
    }
    @media screen and (max-height: 750px) {
      padding-top: 4rem;
    }
    width: 100%;
  }
  .page-footer {
    margin-top: 36px;
  }
}
</style>