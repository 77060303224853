const nationList = [
  {
    id: 'CHN',
    cn: '中国'
  },
  {
    id: 'AUS',
    cn: '澳大利亚'
  },
  {
    id: 'BRA',
    cn: '巴西'
  },
  {
    id: 'BEL',
    cn: '比利时'
  },
  {
    id: 'DEU',
    cn: '德国'
  },
  {
    id: 'RUS',
    cn: '俄罗斯'
  },
  {
    id: 'FRA',
    cn: '法国'
  },
  {
    id: 'KOR',
    cn: '韩国'
  },
  {
    id: 'CAN',
    cn: '加拿大'
  },
  {
    id: 'USA',
    cn: '美国'
  },
  {
    id: 'JPN',
    cn: '日本'
  },
  {
    id: 'HKG',
    cn: '香港'
  },
  {
    id: 'IND',
    cn: '印度'
  },
  {
    id: 'GBR',
    cn: '英国'
  },
  {
    id: 'CHL',
    cn: '智利'
  },
]

export default nationList