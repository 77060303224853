const tournamentList = [
  {
    tournamentId: 1,
    type: 1,
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
    name: '广州新人赛 S5E3',
    date: '21/04/18',
    season: 1,
    status: 0,
    entrants: 10,
    csprRate: 0,
    csprRank: 0,
    region_id: 1,
    online: 0,
    result: '',
    vod: ''
  },
  {
    tournamentId: 2,
    type: 1,
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
    name: '广州月赛 S5E3',
    date: '21/04/18',
    season: 1,
    status: 0,
    entrants: 18,
    csprRate: 1,
    csprRank: 0,
    region_id: 1,
    online: 0,
    result: '',
    vod: ''
  }
]

const newbie = {
  
}

export default {
  'post|http://mock.test/tournament/list': tournamentList
}