export default {
  name: 'NationFlag',
  props: {
    nation: String,
    width: {
      String,
      default: '20px'
    }
  },
  computed: {
    getSrc: function () {
      return `http://image.cnsmash.com/flag/${this.nation}.png`
    }
  }
}