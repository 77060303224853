<template>
  <img :src="getSrc" :style="{width: width}"/>
</template>

<script>
import NationFlag from './NationFlag.js'
export default {
  ...NationFlag
}
</script>

<style scoped>

</style>