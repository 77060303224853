import Vue from 'vue'
import VueRouter from 'vue-router'

import CommonLayout from '@/layout/CommonLayout/CommonLayout.vue'
import ErrorPage from '@/views/ErrorPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/maintain',
    name: 'Maintain',
    component: () => import('../views/Maintain.vue')
  },
  {
    path: '/debug',
    name: 'Debug',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/test',
    name: 'testPage',
    component: () => import('../views/player/characterColor/playerDetail.vue')
  },
  
  {
    path: '/',
    name: 'layout',
    component: CommonLayout,
    children: [
      {
        path: '/developer',
        name: 'Developer',
        component: () => import('../views/Developer.vue'),
        meta: {title: 'developer'}
      },
      {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/index/index.vue'),
        meta: {title: 'Index'}
      },
      {
        path: '/playerlist',
        name: 'PlayerList',
        component: () => import('@/views/player/playerList/playerList.vue'),
        meta: {title: 'playerList'}
      },
      {
        path: '/player/:playerId',
        name: 'PlayerDetail',
        component: () => import('@/views/player/playerDetail/playerDetail.vue'),
        meta: {title: 'playerDetail'}
      },
      {
        path: '/tournamentlist',
        name: 'TournamentList',
        component: () => import('@/views/tournament/tournamentList/tournamentList.vue'),
        meta: {title: 'tournamentList'}
      },
      {
        path: '/tournament/:tournamentId',
        name: 'TournamentDetail',
        component: () => import('@/views/tournament/tournamentDetail/tournamentDetail.vue'),
        meta: {title: 'tournamentDetail'}
      },
      {
        path: '/color',
        name: 'CharacterColor',
        component: () => import('@/views/player/characterColor/playerDetail.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue'),
        meta: {title: 'aboutCSPR'}
      },
    ]
  },
  { 
    path: '*',
    component: ErrorPage,
    meta: {
      footShow: false // true显示，false隐藏
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
