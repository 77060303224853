import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// language pack
import zh from './language/zh'
import en from './language/en'

let locale = localStorage.getItem('locale') || 'zh'

const i18n = new VueI18n({
  locale: locale,
  messages: {
    zh,
    en
  }
})

export default i18n