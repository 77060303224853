<template>
  <div class="breadcrumb">
    <div class="starter">></div>
    <div class="index">
      <a href="#" @click="$router.push({path: '/index'})">{{$t('index')}}</a>
    </div>
    <div class="spliter">></div>
    <div class="current">
      <a href="#" @click="$router.push({path: $route.path})">
        {{$t($route.meta.title)}}
      </a>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  .starter {
    margin-right: 6px;
    color: #888888;
  }
  .spliter {
    margin: 0 6px;
    color: #888888;
  }
  a:hover {
    text-decoration: underline;
  }
}
</style>