<template>
  <div class="footer">
    <div class="content">
      <div class="logo">
        <div class="img">
          <img src="@/assets/resource/CSPR.png" />
        </div>
        <div class="text">{{$t('CSPRSystem')}}</div>
      </div>
      <div class="menu">
        <div class="title">CSPR</div>
        <div class="item">
          <a href="https://weibo.com/u/7120293443">新浪微博</a>
        </div>
        <!-- <div class="item">
          <a href="https://twitter.com/ChinaSmash_PR">Twitter</a>
        </div> -->
      </div>
      <div class="menu">
        <div class="title">{{$t('developer')}}</div>
        <div class="item">Amaki</div>
        <div class="item">
          email：toddheadchan@qq.com
        </div>
      </div>
    </div>
    <div class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备20011363号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .footer {
    overflow: hidden;
    background: #F9FAFB;
    .content {
      display: flex;
      width: 100%;
      max-width: 1050px;
      margin: 0 auto;
      justify-content: center;
      padding-bottom: 50px;
      .logo {
        width: 350px;
        img {
          width: 100px;
          height: 100px;
          margin-top: 60px;
        }
      }
      .menu {
        width: 350px;
        .title {
          margin-top: 60px;
          margin-bottom: 10px;
          height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #555555;
          line-height: 24px;
        }
        .item {
          margin-top: 10px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 300;
          color: #555555;
          line-height: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .footer {
    overflow: hidden;
    background: #F9FAFB;
    .content {
      display: flex;
      width: 100%;
      justify-content: center;
      padding-top: 2rem;
      padding-bottom: 3rem;
      flex-wrap: wrap;
      .logo {
        padding-left: 2.29rem;
        width: 100%;
        display: flex;
        align-items: center;
        img {
          width: 4rem;
        }
        .text {
          font-size: 1.2rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
      }
    }
    .menu {
      margin-top: 1.86rem;
      width: calc(50% - 2.29rem);
      &:nth-child(1) {
        padding-left: 2.29rem;
      }
      &:nth-child(2) {
        padding-right: 2.29rem;
      }
      .title {
        height: 1.57rem;
        font-size: 1rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #555555;
        line-height: 1.57rem;
      }
      .item {
        margin-top: 0.57rem;
        height: 1.43rem;
        font-size: 0.86rem;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #555555;
        line-height: 1.43rem;
      }
    }
  }
}
</style>