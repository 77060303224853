const en = {

  // system
  betainfo: 'BETA VERSION ONLY',

  // header
  CSPRSystem: 'Chinese Smash PR',
  about: 'About',

  // index
  index: 'Home',
  recentEvent: 'Recent Tournament',
  scheduel: 'Scheduel',
  article: 'Article',
  video: 'Videos',
  ranking: 'Ranking',
  entrantCount: 'participants',
  noEvent: 'No Event Today',
  sundayShort: 'Sun',
  mondayShort: 'Mon',
  tuesdayShort: 'Tue',
  wednesdayShort: 'Wed',
  thursdayShort: 'Thu',
  fridayShort: 'Fri',
  saturdayShort: 'Sat',

  // player list
  playerList: 'Player List',
  keyword: 'Keyword',
  characterPick: 'Character',
  region: 'Region',
  search: 'Search',
  CSPRPoing: 'CSPR Point',
  playerStats: 'Stats',
  lastTournament: 'Last Tournament',
  tournamentCount: 'tournament(s)',
  setCount: 'set(s)',
  gameCount: 'game(s)',
  noTournamentData: 'No Tournament Data',

  // player detail
  characterStageData: 'Character/Stage Data',
  usingRate: 'using rate',
  win: 'win',
  lose: 'lose',
  solo: 'solo',
  starter: 'starter',
  counter: 'counter',
  drop: 'drop',
  mostPlayed: 'most played',
  mostWin: 'most win',
  mostLose: 'most lose',
  fighterData: 'Fighter Data',
  stageData: 'Stage Data',
  noCharacterData: 'NO DATA',
  tournamentData: 'Tournament Data',
  CSPRData: 'CSPR Data',
  relatedVideo: 'Related Video',
  date: 'Date',
  tournament: 'Tournament',
  standing: 'Standing',
  entrants: 'Entrants',
  more: 'More',
  seasonSelect: 'Season:',
  set: 'Set',
  game: 'Game',
  regionRank: 'Region',
  csprRank: 'Big Tournament',
  tournamentTypeSelect: 'Tournament Type:',
  regionTournament: 'Region',
  bigTournament: 'Big Tournament',
  unranked: 'Unranked',

  // tournament list
  tournamentList: 'Tournament List',
  CSPRRank: 'CSPR Rank',
  result: 'Result',
  tournamentNotStart: 'Tournament Not Start',
  tournamentRunning: 'Tournament Running',

  // tournament detail
  tournamentDetail: 'Tournament detail',
  player: 'Player',
  setStats: 'Set Info',
  losingTo: 'Losing To',

  // footer
  developer: 'Developer',

  // 角色列表
  mario: 'Mario',
  donkey: 'Donkey Kong',
  link: 'Link',
  samus: 'Samus',
  samusd: 'Dark Samus',
  yoshi: 'Yoshi',
  kirby: 'Kirby',
  fox: 'Fox',
  pikachu: 'Pikachu',
  luigi: 'Luigi',
  ness: 'Ness',
  captain: 'Captain Falcon',
  purin: 'Jugglypuff',
  peach: 'Peach',
  daisy: 'Daisy',
  koopa: 'Bowser',
  ice_climber: 'Ice Climbers',
  sheik: 'Sheik',
  zelda: 'Zelda',
  mariod: 'Dr. Mario',
  pichu: 'Pichu',
  falco: 'Falco',
  marth: 'Marth',
  lucina: 'Lucina',
  younglink: 'Young Link',
  ganon: 'Ganondorf',
  mewtwo: 'Mewtwo',
  roy: 'Roy',
  chrom: 'Chrom',
  gamewatch: 'Mr. Game & Watch',
  metaknight: 'Meta Knight',
  pit: 'Pit',
  pitb: 'Dark Pit',
  szerosuit: 'Zero Suit Samus',
  wario: 'Wario',
  snake: 'Snake',
  ike: 'Ike',
  ptrainer: 'Pokemon Trainer',
  diddy: 'Diddy Kong',
  lucas: 'Lucas',
  sonic: 'Sonic',
  dedede: 'King Dedede',
  lucario: 'Lucario',
  robot: 'R.O.B.',
  toonlink: 'Toon Link',
  wolf: 'Wolf',
  murabito: 'Murabito',
  rockman: 'Mega Man',
  wiifit: 'Wii Fit Trainer',
  rosetta: 'Rosalina & Luma',
  littlemac: 'Little Mac',
  gekkouga: 'Greninja',
  palutena: 'Palutena',
  pacman: 'PAC-MAN',
  reflet: 'Robin',
  shulk: 'Shulk',
  koopajr: 'Bowser Jr.',
  duckhunt: 'Duck Hunt',
  ryu: 'Ryu',
  ken: 'Ken',
  cloud: 'Cloud',
  kamui: 'Corrin',
  bayonetta: 'Bayonetta',
  inkling: 'Inkling',
  ridley: 'Ridley',
  simon: 'Simon',
  richter: 'Richter',
  krool: 'King K. Rool',
  shizue: 'Isabelle',
  gaogaen: 'Incineroar',
  packun: 'Piranha Plant',
  jack: 'Joker',
  brave: 'Hero',
  buddy: 'Banjo & Kazooie',
  dolly: 'Terry',
  master: 'Byleth',
  tantan: 'Min Min',
  pickel: 'Steve/Alex',
  edge: 'Sephirorh',
  eflame: 'Pyra/Mythra',
  demon: 'Kazuya',
  trail: 'Sora',
  miifighter: 'Mii Brawler',
  miiswordsman: 'Mii SwordFighter',
  miigunner: 'Mii Gunner'
}

export default en;