import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=642ee723&scoped=true"
import script from "./ErrorPage.vue?vue&type=script&lang=js"
export * from "./ErrorPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642ee723",
  null
  
)

export default component.exports