<template>
  <div class="header-content">
    <div class="logo-area clickable" @click="$router.push({path: '/index'})">
      <div class="logo-image">
        <img class="cspr-logo" src="@/assets/resource/CSPR_sq.png" mode="aspectFit"/>
      </div>
      <div class="logo-text">
        CSPR 
        <span class="longer-title">{{$t('CSPRSystem')}}</span>
        <span class="beta">BETA</span>
      </div>
    </div>
    <div class="option-area">
      <div class="option-button" v-if="$store.getters.developer" style="color: red">编辑员模式</div>
      <div class="option-button clickable" @click="$router.push({path: '/playerlist'})">{{$t('player')}}</div>
      <div class="option-button clickable" @click="$router.push({path: '/tournamentlist'})">{{$t('tournament')}}</div>
      <div class="option-button clickable" @click="$router.push({path: '/about'})">{{$t('about')}} CSPR</div>
      <div class="language" @click="languageChange">
        <img src='@/assets/icon/language.png' />
      </div>
    </div>
  </div>  
</template>

<script>
export default {
  methods: {
    languageChange: function () {
      let locale = this.$i18n.locale
      if (locale == 'en') {
        this.$i18n.locale = 'zh'
      } else if (locale == 'zh') {
        this.$i18n.locale = 'en'
      }
      localStorage.removeItem('locale')
      localStorage.setItem('locale', this.$i18n.locale)
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .header-content {
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    .logo-area {
      display: flex;
      align-items: center;
      height: 64px;
      overflow: hidden;
      padding-left: 24px;
      padding-right: 12px;
      color: #454F5D;
      cursor: pointer;
      .logo-image {
        .cspr-logo {
          height: 64px;
        }
      }
      .logo-text {
        height: 64px;
        line-height: 64px;
        .beta {
          margin-left: 6px;
        }
      }
    }
    .option-area {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      height: 64px;
      margin-right: 24px;
      .option-button {
        padding-left: 15px;
        padding-right: 15px;
        line-height: 64px;
        height: 64px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #454F5D;
      }
      .clickable:hover {
        color: black;
        cursor: pointer;
      }
      .language {
        margin-left: 12px;
        cursor: pointer;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .header-content {
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    .logo-area {
      display: flex;
      align-items: center;
      height: 4rem;
      overflow: hidden;
      padding-left: 1rem;
      padding-right: 1rem;
      color: #454F5D;
      .logo-image {
        .cspr-logo {
          height: 4rem;
        }
      }
      .logo-text {
        height: 4rem; 
        line-height: 4rem;
        .longer-title {
          display: none
        }
        .beta {
          margin-left: 6px;
        }
      }
    }
    .option-area {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      height: 4rem;
      margin-right: 1rem;
      .option-button {
        padding-left: 1rem;
        padding-right: 1rem;
        line-height: 4rem;
        height: 4rem;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #454F5D;
      }
      .clickable:hover {
        color: black;
      }
      .language {
        margin-left: 1rem;
        img {
          height: 1.5rem;
          widows: 1.5rem;
        }
      }
    }
  }
}
</style>