/***** 静态数据 *****/
import nationList from './nationList.js'
import characterList from './characterList.js'
import fighterSeries from './fighterSeries.js'
import bannerBackground from './bannerBackground.js'
import chartRichCharacter from './chartRichCharacter.js'
import stageFileName from './stageFileName.js'
/***** 动态数据 *****/
import getRegionList from './region.js'

export default {
  /***** 静态数据 *****/
  nationList: nationList,
  characterList: characterList,
  fighterSeries: fighterSeries,
  bannerBackground: bannerBackground,
  chartRichCharacter: chartRichCharacter,
  stageFileName: stageFileName,
  /***** 动态数据 *****/
  getRegionList: getRegionList(),
  getFullRegionList: getRegionList(true)
}