const state = {
  developer: false
}

const mutations = {
  setDeveloper: function (state, dev) {
    state.developer = dev
  }
}

export default {
  namespaced: true,
  state,
  mutations
}