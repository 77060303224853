const playerList = [
  {
    playerId: 1,
    avatar: 'https://images.smash.gg/images/user/1046662/image-0fd1a7c6a535a49b2f64b84cc8afa04a.png',
    playerTag: 'Amaki',
    characters:['younglink', 'pacman', 'inkling'],
    region: {
      name: '广州乱斗堂',
      logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
    },
    nation: 'CHN',
    cspr: 1500,
    stats: {
      tournamentCount: 2,
      setCount: 20,
      gameCount: 55,
      setWin: 12,
      setLose: 8,
      gameWin: 42,
      gameLose: 13 
    },
    lastTournament: {
      date: '2021/03/26',
      name: '逐鹭杯',
      standing: '7th/50'
    }
  },
  {
    playerId: 2,
    avatar: 'https://images.smash.gg/images/user/950793/image-5bcd662e0eccf0ea95ebc1bb84a83a96.png',
    playerTag: '小明',
    characters:['donkey'],
    region: {
      name: '广州乱斗堂',
      logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
    },
    nation: 'CHN',
    cspr: 1500,
    stats: {
      tournamentCount: 45,
      setCount: 129,
      gameCount: 274,
      setWin: 93,
      setLose: 36,
      gameWin: 224,
      gameLose: 50 
    },
    lastTournament: {
      date: '2021/03/26',
      name: '逐鹭杯',
      standing: '5th/50'
    }
  }
]

const AmakiInfo = {
  playerId: 1,
  playerTag: 'Amaki',
  nation: 'CHN',
  region: {
    name: '广州乱斗堂',
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
  },
  character: {
    name: 'younglink',
    color: 5
  }
}

const XmInfo = {
  playerId: 1,
  playerTag: '小明',
  nation: 'CHN',
  region: {
    name: '广州乱斗堂',
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
  },
  character: {
    name: 'donkey',
    color: 1
  }
}

const PYimbaInfo = {
  playerId: 3,
  playerTag: 'Amaki',
  nation: 'CHN',
  region: {
    name: '广州乱斗堂',
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
  },
  character: {
    name: 'captain',
    color: 5
  }
}

const AmakiTournament = [
  {
    id: 1,
    type: 1,
    date: '21/03/26',
    name: '逐鹭杯',
    entrants: '50',
    result: '7th',
    sets: [
      {
        id: 3,
        myTag: 'Amaki',
        myScore: 2,
        oppoTag: 'koko',
        oppoScore: 0,
        result: 1,
        dq: 0,
        game: []
      },
      {
        id: 6,
        myTag: 'Amaki',
        myScore: 2,
        oppoTag: 'LB',
        oppoScore: 0,
        result: 1,
        dq: 0,
        game: []
      },
      {
        id: 1,
        myTag: 'Amaki',
        myScore: 2,
        oppoTag: 'ASH',
        oppoScore: 0,
        result: 1,
        dq: 0,
        game: []
      },
      {
        id: 4,
        myTag: 'Amaki',
        myScore: 0,
        oppoTag: 'Kuroko',
        oppoScore: 2,
        result: 0,
        dq: 0,
        game: []
      },
      {
        id: 5,
        myTag: 'Amaki',
        myScore: 2,
        oppoTag: 'Sider',
        oppoScore: 0,
        result: 1,
        dq: 0,
        game: []
      },
      {
        id: 2,
        myTag: 'Amaki',
        myScore: 0,
        oppoTag: 'Bao',
        oppoScore: 2,
        result: 0,
        dq: 0,
        game: [
          {
            id: 1,
            myCharacter: 'younglink',
            myStock: 0,
            oppoCharacter: 'donkey',
            oppoStock: 2,
            stage: 'city'
          },
          {
            id: 2,
            myCharacter: 'younglink',
            myStock: 0,
            oppoCharacter: 'donkey',
            oppoStock: 2,
            stage: 'city'
          },
        ]
      }
    ]
  },
  {
    id: 2,
    type: 1,
    date: '21/04/11',
    name: '广州周赛 S5E14',
    entrants: '17',
    result: '7th',
    sets: [
      {
        id: 1,
        myTag: 'Amaki',
        myScore: 2,
        oppoTag: 'kagari',
        oppoScore: 0,
        result: 1,
        dq: 0,
        game: [
          {
            id: 1,
            myCharacter: 'link',
            myStock: 1,
            oppoCharacter: 'kamui',
            oppoStock: 0,
            stage: 'stadium',
          },
          {
            id: 2,
            myCharacter: 'pacman',
            myStock: 2,
            oppoCharacter: 'lucina',
            oppoStock:0,
            stage: 'battlefield'
          }
        ]
      },
      {
        id: 2,
        myTag: 'Amaki',
        myScore: 0,
        oppoTag: 'BeGINner',
        oppoScore: 2,
        result: 0,
        dq: 0,
        game: [],
      },
      {
        id: 3,
        myTag: 'Amaki',
        myScore: 2,
        oppoTag: 'eshit',
        oppoScore: 0,
        result: 1,
        dq: 0,
        game: [],
      },
      {
        id: 4,
        myTag: 'Amaki',
        myScore: 0,
        oppoTag: '111',
        oppoScore: 2,
        result: 0,
        dq: 0,
        game: [],
      }
    ]
  }
]

const AmakiCSPR = [
  {
    time: '21/01/01',
    rating: 1500,
    oldRating: 1500,
    standing: 0,
    totalPlayer: 0,
    tournament: '赛季重置'
  },
  {
    time: '21/03/26',
    rating: 1520,
    oldRating: 1500,
    standing: 7,
    totalPlayer: 50,
    tournament: '逐鹭杯'
  },
  {
    time: '21/04/11',
    rating: 1514,
    oldRating: 1520,
    standing: 7,
    totalPlayer: 17,
    tournament: '广州周赛 S5E14'
  }
]

const AmakiCSPRS0 = [
  {
    time: '20/07/01',
    rating: 1500,
    oldRating: 1500,
    standing: 0,
    totalPlayer: 0,
    tournament: '赛季重置'
  },
  {
    time: '20/12/05',
    rating: 1701,
    oldRating: 1500,
    totalPlayer: 100,
    tournament: '云鼎杯 #2'
  }
]

export default {
  'post|http://mock.test/player/playerList': playerList,
  'get|http://mock.test/player/info/1': AmakiInfo,
  'get|http://mock.test/player/info/2': XmInfo,
  'get|http://mock.test/player/info/3': PYimbaInfo,
  'get|http://mock.test/player/tournament/1/1': AmakiTournament,
  'get|http://mock.test/player/cspr/1/1': AmakiCSPR,
  'get|http://mock.test/player/cspr/1/0': AmakiCSPRS0
}