const characterData = [
  {'character': 'younglink', 'rate': 80},
  {'character': 'pacman', 'rate': 10},
  {'character': 'inkling', 'rate': 8},
  {'character': 'rosetta', 'rate': 2}
]

const YLdetail = {
  info: {
    totalWin: 67,
    totalLose: 32,
    pickUp: 4,
    drop: 2,
    solo: 30
  },
  matchUp: {
    mostPlayed: {
      character: 'donkey',
      value: '4-9 (30.8%)'
    },
    mostWin: {
      character: 'jack',
      value: '13-5 (72.2%)'
    },
    mostLose: {
      character: 'shulk',
      value: '0-4 (0.0%)'
    }
  },
  stage: {
    starter: {
      stage: 'stadium',
      value: '80-40 (66.7%)'
    },
    counter: {
      stage: 'city',
      value: '78-14 (84.8%)'
    },
    mostWin: {
      stage: 'city',
      value: '78-14 (84.8%)'
    },
    mostLose: {
      stage: 'lylatcruise',
      value: '2-6 (25.0%)'
    }
  }
}

const PACdetail = {
  info: {
    totalWin: 12,
    totalLose: 4,
    pickUp: 1,
    drop: 0,
    solo: 1
  },
  matchUp: {
    mostPlayed: {
      character: 'chrom',
      value: 7
    },
    mostWin: {
      character: 'link',
      value: 4
    },
    mostLose: {
      cahracter: 'lucina',
      value: 3
    }
  },
  stage: {
    starter: {
      stage: 'battlefield',
      value: 29
    },
    counter: {
      stage: 'cartboard',
      value: 12
    },
    mostWin: {
      stage: 'stadium',
      value: 8
    },
    mostLose: {
      stage: 'end',
      value: 3
    }
  }
}

const Inkdetail = {
  info: {
    totalWin: 4,
    totalLose: 1,
    pickUp: 1,
    drop: 1,
    solo: 2
  },
  matchUp: {
    mostPlayed: {
      character: 'shulk',
      value: 5
    },
    mostWin: {
      character: 'shulk',
      value: 4,
    },
    mostLose: {
      character: 'mario',
      value: 3
    }
  },
  stage: {
    starter: {
      stage: 'village',
      value: 7
    },
    counter: {
      stage: 'village',
      value: 5
    },
    mostWin: {
      stage: 'village',
      value: 5
    },
    mostLose: {
      stage: 'village',
      value: 1
    }
  }
}

const Rosadetail = {
  info: {
    totalWin: 0,
    totalLose: 1,
    pickUp: 0,
    drop: 1,
    solo: 0
  },
  matchUp: {
    mostPlayed: {
      character: 'gamewatch',
      value: 1
    },
    mostWin: {
      character: 'gamewatch',
      value: 1
    },
    mostLose: {
      character: 'gamewatch',
      value: 1
    }
  },
  stage: {
    starter: {
      stage: 'city',
      value: 1
    },
    counter: {
      stage: 'city',
      value: 1
    },
    mostWin: {
      stage: 'city',
      value: 1
    },
    mostLose: {
      stage: 'city',
      value: 1
    }
  }
}

export default {
  'get|http://mock.test/character/rate/1': {rate: characterData, characterData: YLdetail},
  'get|http://mock.test/character/info/1/younglink': YLdetail,
  'get|http://mock.test/character/info/1/pacman': PACdetail,
  'get|http://mock.test/character/info/1/inkling': Inkdetail,
  'get|http://mock.test/character/info/1/rosetta': Rosadetail
}