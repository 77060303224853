const eventList = [
  {
    id: 1,
    date: '2021-03-29',
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
    name: '鼎上战争 九州#3',
    type: 2,
    player: [
      {
        name: '贝丝莉',
        character: 'shulk',
        score: 10
      },
      {
        name: 'Yrden',
        character: 'roy',
        score: 4
      }
    ]
  },
  {
    id: 2,
    date: '2021-03-27',
    logo: 'http://image.cnsmash.com/region/厦门.png',
    name: '逐鹭杯',
    players: 50,
    type: 1,
    player: [
      {
        name: 'Kuroko',
        character: 'dolly'
      },
      {
        name: 'PppZz',
        character: 'gekkouga'
      },
      {
        name: 'yooo冰',
        character: 'zelda'
      }
    ]
  },
  {
    id: 3,
    date: '2021-01-10',
    logo: 'http://image.cnsmash.com/region/泰斗会1up.png',
    name: '西安1UP·秦斗会一月月赛',
    players: 10,
    type: 1,
    player: [
      {
        name: '0',
        character: 'peach'
      },
      {
        name: '烂一地',
        character: 'jack'
      },
      {
        name: '秦斗会苍蓝星',
        character: 'robot'
      }
    ]
  },
  {
    id: 4,
    date: '2021-03-21',
    logo: 'http://image.cnsmash.com/region/重庆.png',
    name: '重庆大乱斗2021年3月月赛',
    players: 7,
    type: 1,
    player: [
      {
        name: 'S.Kahn',
        character: 'shizue'
      },
      {
        name: 'yooking',
        character: 'link'
      },
      {
        name: 'chen',
        character: 'wolf'
      }
    ]
  },
  {
    id: 5,
    date: '2021-02-21',
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
    name: '广州周赛 S5E6',
    type: 1,
    players: 8,
    player: [
      {
        name: '小明',
        character: 'donkey'
      },
      {
        name: 'yooo冰',
        character: 'edge'
      },
      {
        name: '111',
        character: 'littlemac'
      }
    ]
  },
  {
    id: 11,
    date: '2021-03-29',
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
    name: '鼎上战争 九州#3',
    type: 2,
    player: [
      {
        name: '贝丝莉',
        character: 'shulk',
        score: 10
      },
      {
        name: 'Yrden',
        character: 'roy',
        score: 4
      }
    ]
  },
  {
    id: 12,
    date: '2021-03-27',
    logo: 'http://image.cnsmash.com/region/厦门.png',
    name: '逐鹭杯',
    players: 50,
    type: 1,
    player: [
      {
        name: 'Kuroko',
        character: 'dolly'
      },
      {
        name: 'PppZz',
        character: 'gekkouga'
      },
      {
        name: 'yooo冰',
        character: 'zelda'
      }
    ]
  },
  {
    id: 13,
    date: '2021-01-10',
    logo: 'http://image.cnsmash.com/region/泰斗会1up.png',
    name: '西安1UP·秦斗会一月月赛',
    players: 10,
    type: 1,
    player: [
      {
        name: '0',
        character: 'peach'
      },
      {
        name: '烂一地',
        character: 'jack'
      },
      {
        name: '秦斗会苍蓝星',
        character: 'robot'
      }
    ]
  },
  {
    id: 14,
    date: '2021-03-21',
    logo: 'http://image.cnsmash.com/region/重庆.png',
    name: '重庆大乱斗2021年3月月赛',
    players: 7,
    type: 1,
    player: [
      {
        name: 'S.Kahn',
        character: 'shizue'
      },
      {
        name: 'yooking',
        character: 'link'
      },
      {
        name: 'chen',
        character: 'wolf'
      }
    ]
  },
  {
    id: 15,
    date: '2021-02-21',
    logo: 'http://image.cnsmash.com/region/广州乱斗堂.png',
    name: '广州周赛 S5E6',
    type: 1,
    players: 8,
    player: [
      {
        name: '小明',
        character: 'donkey'
      },
      {
        name: 'yooo冰',
        character: 'edge'
      },
      {
        name: '111',
        character: 'littlemac'
      }
    ]
  }
]

export default {
  'get|http://mock.test/recentEvent': eventList,
}