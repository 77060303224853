<template>
  <img :src="getSrc" :style="{width: width, height: width}"/>
</template>

<script>
import BigIcon from './BigIcon.js'
export default {
  ...BigIcon
}
</script>

<style scoped>

</style>