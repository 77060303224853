<template>
  <div class="custom-title" :style="{width: width, borderLeft: `5px solid ${color}`}">
    {{text}}
  </div>  
</template>

<script>
export default {
  name: 'CustomTitle',
  props: {
    text: String,
    width: {
      type: String,
      default: '100%'
    },
    color: {
      type: String,
      default: '#67b7e6'
    }
  }
}
</script>

<style lang="less" scoped>
.custom-title {
  border-radius: 4px;
  margin: 12px 6px;
  height: 30px;
  line-height: 30px;
  padding-left: 12px;
}
</style>