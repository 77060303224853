const fighterSeries = {
  bayonetta: 'Bayonetta',
  brave: 'DragonQuest',
  buddy: 'BanjoKazooie',
  captain: 'FZero',
  chrom: 'FireEmblem',
  cloud: 'FinalFantasy',
  daisy: 'Mario',
  dedede: 'Kirby',
  demon: 'Tekken',
  diddy: 'DK',
  dolly: 'FatalFury',
  donkey: 'DK',
  duckhunt: 'DuckHunt',
  edge: 'FinalFantasy',
  eflame: 'Xenoblade',
  falco: 'StarFox',
  fox: 'StarFox',
  gamewatch: 'GameWatch',
  ganon: 'Zelda',
  gaogaen: 'Pokemon',
  gekkouga: 'Pokemon',
  ice_climber: 'IceClimber',
  ike: 'FireEmblem',
  inkling: 'Splatoon',
  jack: 'Persona',
  kamui: 'FireEmblem',
  ken: 'StreetFighter',
  kirby: 'Kirby',
  koopa: 'Mario',
  koopajr: 'Mario',
  krool: 'DK',
  link: 'Zelda',
  littlemac: 'PunchOut',
  lucario: 'Pokemon',
  lucas: 'Earthbound',
  lucina: 'FireEmblem',
  luigi: 'Mario',
  mario: 'Mario',
  mariod: 'Mario',
  marth: 'FireEmblem',
  master: 'FireEmblem',
  metaknight: 'Kirby',
  mewtwo: 'Pokemon',
  miifighter: 'SmashBros',
  miigunner: 'SmashBros',
  miiswordsman: 'SmashBros',
  murabito: 'AnimalCrossing',
  ness: 'Earthbound',
  packun: 'Mario',
  pacman: 'PacMan',
  palutena: 'KidIcarus',
  peach: 'Mario',
  pichu: 'Pokemon',
  pikachu: 'Pokemon',
  pikmin: 'Pikmin',
  pit: 'KidIcarus',
  pitb: 'KidIcarus',
  ptrainer: 'Pokemon',
  purin: 'Pokemon',
  reflet: 'FireEmblem',
  richter: 'Castlevania',
  ridley: 'Metroid',
  robot: 'ROB',
  rockman: 'MegaMan',
  rosetta: 'Mario',
  roy: 'FireEmblem',
  ryu: 'StreetFighter',
  samus: 'Metroid',
  samusd: 'Metroid',
  sheik: 'Zelda',
  shizue: 'AnimalCrossing',
  shulk: 'Xenoblade',
  simon: 'Castlevania',
  snake: 'MetalGear',
  sonic: 'Sonic',
  szerosuit: 'Metroid',
  toonlink: 'Zelda',
  tantan: 'ARMS',
  trail: 'KingdomHearts',
  wario: 'Wario',
  wiifit: 'WiiFit',
  wolf: 'StarFox',
  yoshi: 'Mario',
  younglink: 'Zelda',
  zelda: 'Zelda'
}

export default fighterSeries