<template>
  <div class="error">
    <div class="content">
      ERROR URL
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>

</style>