const imgSize = 26

const chartRichCharacter = {
  mario: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/mario.png'
    }
  },
  donkey: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/donkey.png'
    }
  },
  link: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/link.png'
    }
  },
  samus: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/samus.png'
    }
  },
  samusd: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/samusd.png'
    }
  },
  yoshi: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/yoshi.png'
    }
  },
  kirby: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/kirby.png'
    }
  },
  fox: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/fox.png'
    }
  },
  pikachu: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/pikachu.png'
    }
  },
  luigi: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/luigi.png'
    }
  },
  ness: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/ness.png'
    }
  },
  captain: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/captain.png'
    }
  },
  purin: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/purin.png'
    }
  },
  peach: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/peach.png'
    }
  },
  daisy: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/daisy.png'
    }
  },
  koopa: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/koopa.png'
    }
  },
  ice_climber: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/ice_climber.png'
    }
  },
  sheik: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/sheik.png'
    }
  },
  zelda: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/zelda.png'
    }
  },
  mariod: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/mariod.png'
    }
  },
  pichu: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/pichu.png'
    }
  },
  falco: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/falco.png'
    }
  },
  marth: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/marth.png'
    }
  },
  lucina: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/lucina.png'
    }
  },
  younglink: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/younglink.png'
    }
  },
  ganon: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/ganon.png'
    }
  },
  mewtwo: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/mewtwo.png'
    }
  },
  roy: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/roy.png'
    }
  },
  chrom: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/chrom.png'
    }
  },
  gamewatch: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/gamewatch.png'
    }
  },
  metaknight: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/metaknight.png'
    }
  },
  pit: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/pit.png'
    }
  },
  pitb: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/pitb.png'
    }
  },
  szerosuit: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/szerosuit.png'
    }
  },
  wario: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/wario.png'
    }
  },
  snake: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/snake.png'
    }
  },
  ike: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/ike.png'
    }
  },
  ptrainer: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/ptrainer.png'
    }
  },
  diddy: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/diddy.png'
    }
  },
  lucas: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/lucas.png'
    }
  },
  sonic: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/sonic.png'
    }
  },
  dedede: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/dedede.png'
    }
  },
  lucario: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/lucario.png'
    }
  },
  robot: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/robot.png'
    }
  },
  toonlink: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/toonlink.png'
    }
  },
  wolf: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/wolf.png'
    }
  },
  murabito: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/murabito.png'
    }
  },
  rockman: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/rockman.png'
    }
  },
  wiifit: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/wiifit.png'
    }
  },
  rosetta: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/rosetta.png'
    }
  },
  littlemac: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/littlemac.png'
    }
  },
  gekkouga: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/gekkouga.png'
    }
  },
  palutena: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/palutena.png'
    }
  },
  pacman: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/pacman.png'
    }
  },
  reflet: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/reflet.png'
    }
  },
  shulk: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/shulk.png'
    }
  },
  koopajr: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/koopajr.png'
    }
  },
  duckhunt: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/duckhunt.png'
    }
  },
  ryu: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/ryu.png'
    }
  },
  ken: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/ken.png'
    }
  },
  cloud: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/cloud.png'
    }
  },
  kamui: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/kamui.png'
    }
  },
  bayonetta: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/bayonetta.png'
    }
  },
  inkling: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/inkling.png'
    }
  },
  ridley: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/ridley.png'
    }
  },
  simon: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/simon.png'
    }
  },
  richter: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/richter.png'
    }
  },
  krool: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/krool.png'
    }
  },
  shizue: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/shizue.png'
    }
  },
  gaogaen: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/gaogaen.png'
    }
  },
  packun: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/packun.png'
    }
  },
  jack: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/jack.png'
    }
  },
  brave: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/brave.png'
    }
  },
  buddy: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/buddy.png'
    }
  },
  dolly: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/dolly.png'
    }
  },
  master: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/master.png'
    }
  },
  tantan: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/tantan.png'
    }
  },
  pickel: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/pickel.png'
    }
  },
  edge: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/edge.png'
    }
  },
  eflame: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/eflame.png'
    }
  },
  elight: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/elight.png'
    }
  },
  demon: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/demon.png'
    }
  },
  trail: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/trail.png'
    }
  },
  miifighter: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/miifighter.png'
    }
  },
  miiswordsman: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/miiswordsman.png'
    }
  },
  miigunner: {
    height: imgSize,
    backgroundColor: {
      image: 'http://image.cnsmash.com/stockiconhq/miigunner.png'
    }
  },
}

export default chartRichCharacter