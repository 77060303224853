import store from '@/store'
import { getRegionListApi } from '@/api/region'

const SumUpRegion = {
  name: '所有地区',
  logo: 'http://image.cnsmash.com/region/CSPR.png',
  description: '所有地区',
  regionId: -1
}

export default function getRegionList(fullList = false) {
  // let localRegionList = store.getters.regionList
  var localRegionList = sessionStorage.getItem('regionList') 
  if (localRegionList) {
    localRegionList = JSON.parse(localRegionList)
    return new Promise((resolve, reject) => {
      if (fullList) {
        resolve([SumUpRegion, ...localRegionList])
      } else if (fullList == false) {
        resolve(localRegionList)
      }
    })
  } else return getRegionListApi().then(res => {
    let regionList = res.data.data
    // store.commit('region/setRegionList', regionList)
    sessionStorage.setItem('regionList', JSON.stringify(regionList))
    return new Promise((resolve, reject) => {
      if (fullList) {
        resolve([SumUpRegion, ...regionList])
      } else if (fullList == false) {
        resolve(regionList)
      }
    })
  })
}