export default {
  name: 'DiamondIcon',
  props: {
    character: String,
    color: Number,
    width: {
      type: String,
      default: '20px'
    },
    opacity: {
      type: Number,
      default: 1
    }
  },
  computed: {
    getSrc: function () {
      return `http://image.cnsmash.com/diamondicon/chara_4_${this.character}_0${this.color}.png`
    }
  }
}