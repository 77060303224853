const bannerBackground = {
  bayonetta: [
    { color:'rgba(65, 70, 86, 0.4)', shallow:'rgba(111, 119, 146, 0.95)' },
    { color:'rgba(174, 53, 56, 0.4)', shallow:'rgba(255, 90, 95, 0.95)' },
    { color:'rgba(192, 130, 19, 0.4)', shallow:'rgba(255, 221, 32, 0.95)' },
    { color:'rgba(53, 96, 55, 0.4)', shallow:'rgba(90, 163, 94, 0.95)' },
    { color:'rgba(176, 185, 184, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(181, 100, 88, 0.4)', shallow:'rgba(255, 170, 150, 0.95)' },
    { color:'rgba(196, 191, 190, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(48, 45, 106, 0.4)', shallow:'rgba(82, 77, 180, 0.95)' }
  ],
  brave: [
    { color:'rgba(190, 162, 215, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(153, 80, 105, 0.4)', shallow:'rgba(255, 136, 179, 0.95)' },
    { color:'rgba(83, 119, 127, 0.4)', shallow:'rgba(141, 202, 216, 0.95)' },
    { color:'rgba(241, 168, 128, 0.4)', shallow:'rgba(255, 255, 218, 0.95)' },
    { color:'rgba(176, 120, 111, 0.4)', shallow:'rgba(255, 204, 189, 0.95)' },
    { color:'rgba(59, 36, 70, 0.4)', shallow:'rgba(100, 61, 119, 0.95)' },
    { color:'rgba(35, 44, 60, 0.4)', shallow:'rgba(60, 75, 102, 0.95)' },
    { color:'rgba(76, 105, 142, 0.4)', shallow:'rgba(129, 179, 241, 0.95)' }
  ],
  buddy: [
    { color:'rgba(116, 60, 46, 0.4)', shallow:'rgba(197, 102, 78, 0.95)' },
    { color:'rgba(191, 107, 120, 0.4)', shallow:'rgba(255, 182, 204, 0.95)' },
    { color:'rgba(30, 29, 33, 0.4)', shallow:'rgba(51, 49, 56, 0.95)' },
    { color:'rgba(122, 49, 63, 0.4)', shallow:'rgba(207, 83, 107, 0.95)' },
    { color:'rgba(206, 203, 204, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(165, 111, 48, 0.4)', shallow:'rgba(255, 189, 82, 0.95)' },
    { color:'rgba(48, 94, 145, 0.4)', shallow:'rgba(82, 160, 247, 0.95)' },
    { color:'rgba(43, 28, 41, 0.4)', shallow:'rgba(73, 48, 70, 0.95)' }
  ],
  captain: [
    { color:'rgba(51, 42, 62, 0.4)', shallow:'rgba(87, 71, 105, 0.95)' },
    { color:'rgba(35, 29, 32, 0.4)', shallow:'rgba(60, 49, 54, 0.95)' },
    { color:'rgba(86, 37, 41, 0.4)', shallow:'rgba(146, 63, 70, 0.95)' },
    { color:'rgba(31, 45, 33, 0.4)', shallow:'rgba(53, 77, 56, 0.95)' },
    { color:'rgba(27, 31, 42, 0.4)', shallow:'rgba(46, 53, 71, 0.95)' },
    { color:'rgba(137, 107, 121, 0.4)', shallow:'rgba(233, 182, 206, 0.95)' },
    { color:'rgba(239, 213, 113, 0.4)', shallow:'rgba(255, 255, 192, 0.95)' },
    { color:'rgba(89, 108, 122, 0.4)', shallow:'rgba(151, 184, 207, 0.95)' }
  ],
  chrom: [
    { color:'rgba(134, 147, 173, 0.4)', shallow:'rgba(228, 250, 255, 0.95)' },
    { color:'rgba(101, 67, 61, 0.4)', shallow:'rgba(172, 114, 104, 0.95)' },
    { color:'rgba(104, 95, 67, 0.4)', shallow:'rgba(177, 162, 114, 0.95)' },
    { color:'rgba(138, 102, 73, 0.4)', shallow:'rgba(235, 173, 124, 0.95)' },
    { color:'rgba(57, 62, 79, 0.4)', shallow:'rgba(97, 105, 134, 0.95)' },
    { color:'rgba(99, 84, 87, 0.4)', shallow:'rgba(168, 143, 148, 0.95)' },
    { color:'rgba(138, 73, 32, 0.4)', shallow:'rgba(235, 124, 54, 0.95)' },
    { color:'rgba(50, 65, 76, 0.4)', shallow:'rgba(85, 111, 129, 0.95)' }
  ],
  cloud: [
    { color:'rgba(40, 38, 41, 0.4)', shallow:'rgba(68, 65, 70, 0.95)' },
    { color:'rgba(45, 43, 44, 0.4)', shallow:'rgba(77, 73, 75, 0.95)' },
    { color:'rgba(82, 90, 120, 0.4)', shallow:'rgba(139, 153, 204, 0.95)' },
    { color:'rgba(87, 102, 109, 0.4)', shallow:'rgba(148, 173, 185, 0.95)' },
    { color:'rgba(104, 74, 68, 0.4)', shallow:'rgba(177, 126, 116, 0.95)' },
    { color:'rgba(25, 24, 26, 0.4)', shallow:'rgba(43, 41, 44, 0.95)' },
    { color:'rgba(99, 84, 74, 0.4)', shallow:'rgba(168, 143, 126, 0.95)' },
    { color:'rgba(27, 23, 30, 0.4)', shallow:'rgba(46, 39, 51, 0.95)' }
  ],
  daisy: [
    { color:'rgba(211, 99, 41, 0.4)', shallow:'rgba(255, 168, 70, 0.95)' },
    { color:'rgba(131, 186, 99, 0.4)', shallow:'rgba(223, 255, 168, 0.95)' },
    { color:'rgba(229, 105, 92, 0.4)', shallow:'rgba(255, 179, 156, 0.95)' },
    { color:'rgba(130, 157, 238, 0.4)', shallow:'rgba(221, 255, 255, 0.95)' },
    { color:'rgba(232, 100, 93, 0.4)', shallow:'rgba(255, 170, 158, 0.95)' },
    { color:'rgba(159, 146, 196, 0.4)', shallow:'rgba(255, 248, 255, 0.95)' },
    { color:'rgba(223, 191, 172, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(79, 136, 98, 0.4)', shallow:'rgba(134, 231, 167, 0.95)' }
  ],
  dedede: [
    { color:'rgba(159, 42, 26, 0.4)', shallow:'rgba(255, 71, 44, 0.95)' },
    { color:'rgba(201, 135, 239, 0.4)', shallow:'rgba(255, 230, 255, 0.95)' },
    { color:'rgba(40, 72, 69, 0.4)', shallow:'rgba(68, 122, 117, 0.95)' },
    { color:'rgba(52, 26, 70, 0.4)', shallow:'rgba(88, 44, 119, 0.95)' },
    { color:'rgba(128, 156, 173, 0.4)', shallow:'rgba(218, 255, 255, 0.95)' },
    { color:'rgba(157, 147, 124, 0.4)', shallow:'rgba(255, 250, 211, 0.95)' },
    { color:'rgba(130, 176, 188, 0.4)', shallow:'rgba(221, 255, 255, 0.95)' },
    { color:'rgba(42, 34, 51, 0.4)', shallow:'rgba(71, 58, 87, 0.95)' }
  ],
  diddy: [
    { color:'rgba(185, 41, 36, 0.4)', shallow:'rgba(255, 70, 61, 0.95)' },
    { color:'rgba(168, 127, 87, 0.4)', shallow:'rgba(255, 216, 148, 0.95)' },
    { color:'rgba(218, 137, 154, 0.4)', shallow:'rgba(255, 233, 255, 0.95)' },
    { color:'rgba(138, 85, 135, 0.4)', shallow:'rgba(235, 145, 230, 0.95)' },
    { color:'rgba(130, 170, 85, 0.4)', shallow:'rgba(221, 255, 145, 0.95)' },
    { color:'rgba(135, 150, 225, 0.4)', shallow:'rgba(230, 255, 255, 0.95)' },
    { color:'rgba(194, 154, 118, 0.4)', shallow:'rgba(255, 255, 201, 0.95)' },
    { color:'rgba(88, 140, 180, 0.4)', shallow:'rgba(150, 238, 255, 0.95)' }
  ],
  dolly: [
    { color:'rgba(223, 121, 110, 0.4)', shallow:'rgba(255, 206, 187, 0.95)' },
    { color:'rgba(73, 77, 142, 0.4)', shallow:'rgba(124, 131, 241, 0.95)' },
    { color:'rgba(157, 106, 80, 0.4)', shallow:'rgba(255, 180, 136, 0.95)' },
    { color:'rgba(88, 147, 72, 0.4)', shallow:'rgba(150, 250, 122, 0.95)' },
    { color:'rgba(166, 81, 50, 0.4)', shallow:'rgba(255, 138, 85, 0.95)' },
    { color:'rgba(48, 46, 49, 0.4)', shallow:'rgba(82, 78, 83, 0.95)' },
    { color:'rgba(43, 37, 40, 0.4)', shallow:'rgba(73, 63, 68, 0.95)' },
    { color:'rgba(192, 144, 71, 0.4)', shallow:'rgba(255, 245, 121, 0.95)' }
  ],
  demon: [
    { color:'rgba(208, 156, 114, 0.4)', shallow:'rgba(255, 255, 194, 0.95)' },
    { color:'rgba(24, 22, 29, 0.4)', shallow:'rgba(41, 37, 49, 0.95)' },
    { color:'rgba(98, 42, 46, 0.4)', shallow:'rgba(167, 71, 78, 0.95)' },
    { color:'rgba(102, 94, 102, 0.4)', shallow:'rgba(173, 160, 173, 0.95)' },
    { color:'rgba(91, 108, 121, 0.4)', shallow:'rgba(155, 184, 206, 0.95)' },
    { color:'rgba(76, 43, 76, 0.4)', shallow:'rgba(129, 73, 129, 0.95)' },
    { color:'rgba(48, 39, 41, 0.4)', shallow:'rgba(82, 66, 70, 0.95)' },
    { color:'rgba(185, 137, 92, 0.4)', shallow:'rgba(255, 233, 156, 0.95)' }
  ],
  donkey: [
    { color:'rgba(89, 37, 9, 0.4)', shallow:'rgba(151, 63, 15, 0.95)' },
    { color:'rgba(31, 30, 28, 0.4)', shallow:'rgba(53, 51, 48, 0.95)' },
    { color:'rgba(129, 47, 19, 0.4)', shallow:'rgba(219, 80, 32, 0.95)' },
    { color:'rgba(43, 18, 71, 0.4)', shallow:'rgba(73, 31, 121, 0.95)' },
    { color:'rgba(47, 103, 14, 0.4)', shallow:'rgba(80, 175, 24, 0.95)' },
    { color:'rgba(132, 131, 130, 0.4)', shallow:'rgba(224, 223, 221, 0.95)' },
    { color:'rgba(174, 119, 24, 0.4)', shallow:'rgba(255, 202, 41, 0.95)' },
    { color:'rgba(133, 49, 76, 0.4)', shallow:'rgba(226, 83, 129, 0.95)' }
  ],
  duckhunt: [
    { color:'rgba(209, 111, 56, 0.4)', shallow:'rgba(255, 189, 95, 0.95)' },
    { color:'rgba(51, 37, 31, 0.4)', shallow:'rgba(87, 63, 53, 0.95)' },
    { color:'rgba(106, 78, 53, 0.4)', shallow:'rgba(180, 133, 90, 0.95)' },
    { color:'rgba(116, 84, 47, 0.4)', shallow:'rgba(197, 143, 80, 0.95)' },
    { color:'rgba(147, 138, 125, 0.4)', shallow:'rgba(250, 235, 213, 0.95)' },
    { color:'rgba(189, 104, 54, 0.4)', shallow:'rgba(255, 177, 92, 0.95)' },
    { color:'rgba(156, 113, 56, 0.4)', shallow:'rgba(255, 192, 95, 0.95)' },
    { color:'rgba(108, 68, 45, 0.4)', shallow:'rgba(184, 116, 77, 0.95)' }
  ],
  edge: [
    { color:'rgba(14, 12, 19, 0.4)', shallow:'rgba(24, 20, 32, 0.95)' },
    { color:'rgba(58, 58, 79, 0.4)', shallow:'rgba(99, 99, 134, 0.95)' },
    { color:'rgba(78, 89, 96, 0.4)', shallow:'rgba(133, 151, 163, 0.95)' },
    { color:'rgba(72, 55, 56, 0.4)', shallow:'rgba(122, 94, 95, 0.95)' },
    { color:'rgba(84, 84, 83, 0.4)', shallow:'rgba(143, 143, 141, 0.95)' },
    { color:'rgba(77, 85, 107, 0.4)', shallow:'rgba(131, 145, 182, 0.95)' },
    { color:'rgba(33, 33, 44, 0.4)', shallow:'rgba(56, 56, 75, 0.95)' },
    { color:'rgba(38, 28, 34, 0.4)', shallow:'rgba(65, 48, 58, 0.95)' }
  ],
  eflame: [
    { color:'rgba(184, 84, 81, 0.4)', shallow:'rgba(255, 143, 138, 0.95)' },
    { color:'rgba(46, 139, 113, 0.4)', shallow:'rgba(78, 236, 192, 0.95)' },
    { color:'rgba(233, 137, 120, 0.4)', shallow:'rgba(255, 233, 204, 0.95)' },
    { color:'rgba(223, 131, 103, 0.4)', shallow:'rgba(255, 223, 175, 0.95)' },
    { color:'rgba(35, 25, 43, 0.4)', shallow:'rgba(60, 43, 73, 0.95)' },
    { color:'rgba(88, 126, 188, 0.4)', shallow:'rgba(150, 214, 255, 0.95)' },
    { color:'rgba(224, 100, 91, 0.4)', shallow:'rgba(255, 170, 155, 0.95)' },
    { color:'rgba(164, 106, 54, 0.4)', shallow:'rgba(255, 180, 92, 0.95)' }
  ],
  falco: [
    { color:'rgba(74, 99, 219, 0.4)', shallow:'rgba(126, 168, 255, 0.95)' },
    { color:'rgba(155, 83, 35, 0.4)', shallow:'rgba(255, 141, 60, 0.95)' },
    { color:'rgba(59, 52, 100, 0.4)', shallow:'rgba(100, 88, 170, 0.95)' },
    { color:'rgba(214, 112, 115, 0.4)', shallow:'rgba(255, 190, 196, 0.95)' },
    { color:'rgba(52, 60, 30, 0.4)', shallow:'rgba(88, 102, 51, 0.95)' },
    { color:'rgba(68, 69, 91, 0.4)', shallow:'rgba(116, 117, 155, 0.95)' },
    { color:'rgba(77, 113, 118, 0.4)', shallow:'rgba(131, 192, 201, 0.95)' },
    { color:'rgba(105, 63, 101, 0.4)', shallow:'rgba(179, 107, 172, 0.95)' }
  ],
  fox: [
    { color:'rgba(158, 122, 85, 0.4)', shallow:'rgba(255, 207, 145, 0.95)' },
    { color:'rgba(110, 89, 65, 0.4)', shallow:'rgba(187, 151, 111, 0.95)' },
    { color:'rgba(170, 71, 72, 0.4)', shallow:'rgba(255, 121, 122, 0.95)' },
    { color:'rgba(105, 147, 103, 0.4)', shallow:'rgba(179, 250, 175, 0.95)' },
    { color:'rgba(159, 117, 73, 0.4)', shallow:'rgba(255, 199, 124, 0.95)' },
    { color:'rgba(109, 99, 76, 0.4)', shallow:'rgba(185, 168, 129, 0.95)' },
    { color:'rgba(229, 201, 108, 0.4)', shallow:'rgba(255, 255, 184, 0.95)' },
    { color:'rgba(90, 70, 96, 0.4)', shallow:'rgba(153, 119, 163, 0.95)' }
  ],
  gamewatch: [
    { color:'rgba(4, 4, 4, 0.4)', shallow:'rgba(7, 7, 7, 0.95)' },
    { color:'rgba(108, 4, 19, 0.4)', shallow:'rgba(184, 7, 32, 0.95)' },
    { color:'rgba(107, 124, 4, 0.4)', shallow:'rgba(182, 211, 7, 0.95)' },
    { color:'rgba(27, 19, 107, 0.4)', shallow:'rgba(46, 32, 182, 0.95)' },
    { color:'rgba(8, 76, 91, 0.4)', shallow:'rgba(14, 129, 155, 0.95)' },
    { color:'rgba(35, 155, 147, 0.4)', shallow:'rgba(60, 255, 250, 0.95)' },
    { color:'rgba(12, 44, 8, 0.4)', shallow:'rgba(20, 75, 14, 0.95)' },
    { color:'rgba(188, 188, 188, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' }
  ],
  ganon: [
    { color:'rgba(51, 23, 15, 0.4)', shallow:'rgba(87, 39, 26, 0.95)' },
    { color:'rgba(46, 21, 28, 0.4)', shallow:'rgba(78, 36, 48, 0.95)' },
    { color:'rgba(22, 15, 39, 0.4)', shallow:'rgba(37, 26, 66, 0.95)' },
    { color:'rgba(49, 76, 42, 0.4)', shallow:'rgba(83, 129, 71, 0.95)' },
    { color:'rgba(33, 18, 38, 0.4)', shallow:'rgba(56, 31, 65, 0.95)' },
    { color:'rgba(48, 27, 18, 0.4)', shallow:'rgba(82, 46, 31, 0.95)' },
    { color:'rgba(32, 23, 33, 0.4)', shallow:'rgba(54, 39, 56, 0.95)' },
    { color:'rgba(45, 25, 23, 0.4)', shallow:'rgba(77, 43, 39, 0.95)' }
  ],
  gaogaen: [
    { color:'rgba(222, 83, 76, 0.4)', shallow:'rgba(255, 141, 129, 0.95)' },
    { color:'rgba(148, 184, 99, 0.4)', shallow:'rgba(252, 255, 168, 0.95)' },
    { color:'rgba(149, 78, 83, 0.4)', shallow:'rgba(253, 133, 141, 0.95)' },
    { color:'rgba(117, 141, 210, 0.4)', shallow:'rgba(199, 240, 255, 0.95)' },
    { color:'rgba(205, 113, 53, 0.4)', shallow:'rgba(255, 192, 90, 0.95)' },
    { color:'rgba(111, 72, 102, 0.4)', shallow:'rgba(189, 122, 173, 0.95)' },
    { color:'rgba(51, 43, 52, 0.4)', shallow:'rgba(87, 73, 88, 0.95)' },
    { color:'rgba(212, 83, 70, 0.4)', shallow:'rgba(255, 141, 119, 0.95)' }
  ],
  gekkouga: [
    { color:'rgba(34, 34, 97, 0.4)', shallow:'rgba(58, 58, 165, 0.95)' },
    { color:'rgba(89, 9, 16, 0.4)', shallow:'rgba(151, 15, 27, 0.95)' },
    { color:'rgba(118, 43, 81, 0.4)', shallow:'rgba(201, 73, 138, 0.95)' },
    { color:'rgba(27, 25, 25, 0.4)', shallow:'rgba(46, 43, 43, 0.95)' },
    { color:'rgba(87, 33, 98, 0.4)', shallow:'rgba(148, 56, 167, 0.95)' },
    { color:'rgba(30, 50, 19, 0.4)', shallow:'rgba(51, 85, 32, 0.95)' },
    { color:'rgba(101, 94, 101, 0.4)', shallow:'rgba(172, 160, 172, 0.95)' },
    { color:'rgba(49, 24, 73, 0.4)', shallow:'rgba(83, 41, 124, 0.95)' }
  ],
  ice_climber: [
    { color:'rgba(100, 108, 204, 0.4)', shallow:'rgba(170, 184, 255, 0.95)' },
    { color:'rgba(78, 127, 206, 0.4)', shallow:'rgba(133, 216, 255, 0.95)' },
    { color:'rgba(64, 104, 62, 0.4)', shallow:'rgba(109, 177, 105, 0.95)' },
    { color:'rgba(57, 61, 106, 0.4)', shallow:'rgba(97, 104, 180, 0.95)' },
    { color:'rgba(149, 60, 69, 0.4)', shallow:'rgba(253, 102, 117, 0.95)' },
    { color:'rgba(114, 80, 57, 0.4)', shallow:'rgba(194, 136, 97, 0.95)' },
    { color:'rgba(146, 170, 215, 0.4)', shallow:'rgba(248, 255, 255, 0.95)' },
    { color:'rgba(173, 109, 73, 0.4)', shallow:'rgba(255, 185, 124, 0.95)' }
  ],
  ike: [
    { color:'rgba(104, 74, 62, 0.4)', shallow:'rgba(177, 126, 105, 0.95)' },
    { color:'rgba(108, 59, 39, 0.4)', shallow:'rgba(184, 100, 66, 0.95)' },
    { color:'rgba(123, 65, 52, 0.4)', shallow:'rgba(209, 111, 88, 0.95)' },
    { color:'rgba(88, 49, 43, 0.4)', shallow:'rgba(150, 83, 73, 0.95)' },
    { color:'rgba(198, 140, 35, 0.4)', shallow:'rgba(255, 238, 60, 0.95)' },
    { color:'rgba(118, 148, 74, 0.4)', shallow:'rgba(201, 252, 126, 0.95)' },
    { color:'rgba(101, 133, 155, 0.4)', shallow:'rgba(172, 226, 255, 0.95)' },
    { color:'rgba(79, 38, 38, 0.4)', shallow:'rgba(134, 65, 65, 0.95)' }
  ],
  inkling: [
    { color:'rgba(230, 112, 52, 0.4)', shallow:'rgba(255, 190, 88, 0.95)' },
    { color:'rgba(77, 90, 223, 0.4)', shallow:'rgba(131, 153, 255, 0.95)' },
    { color:'rgba(233, 219, 41, 0.4)', shallow:'rgba(255, 255, 70, 0.95)' },
    { color:'rgba(168, 210, 63, 0.4)', shallow:'rgba(255, 255, 107, 0.95)' },
    { color:'rgba(160, 65, 67, 0.4)', shallow:'rgba(255, 111, 114, 0.95)' },
    { color:'rgba(88, 176, 203, 0.4)', shallow:'rgba(150, 255, 255, 0.95)' },
    { color:'rgba(166, 59, 178, 0.4)', shallow:'rgba(255, 100, 255, 0.95)' },
    { color:'rgba(82, 38, 146, 0.4)', shallow:'rgba(139, 65, 248, 0.95)' }
  ],
  jack: [
    { color:'rgba(19, 16, 20, 0.4)', shallow:'rgba(32, 27, 34, 0.95)' },
    { color:'rgba(91, 74, 110, 0.4)', shallow:'rgba(155, 126, 187, 0.95)' },
    { color:'rgba(53, 89, 153, 0.4)', shallow:'rgba(90, 151, 255, 0.95)' },
    { color:'rgba(206, 187, 80, 0.4)', shallow:'rgba(255, 255, 136, 0.95)' },
    { color:'rgba(187, 82, 82, 0.4)', shallow:'rgba(255, 139, 139, 0.95)' },
    { color:'rgba(161, 148, 139, 0.4)', shallow:'rgba(255, 252, 236, 0.95)' },
    { color:'rgba(27, 24, 28, 0.4)', shallow:'rgba(46, 41, 48, 0.95)' },
    { color:'rgba(47, 38, 47, 0.4)', shallow:'rgba(80, 65, 80, 0.95)' }
  ],
  kamui: [
    { color:'rgba(216, 201, 192, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(222, 180, 186, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(149, 65, 73, 0.4)', shallow:'rgba(253, 111, 124, 0.95)' },
    { color:'rgba(225, 131, 97, 0.4)', shallow:'rgba(255, 223, 165, 0.95)' },
    { color:'rgba(118, 124, 167, 0.4)', shallow:'rgba(201, 211, 255, 0.95)' },
    { color:'rgba(231, 127, 198, 0.4)', shallow:'rgba(255, 216, 255, 0.95)' },
    { color:'rgba(73, 123, 108, 0.4)', shallow:'rgba(124, 209, 184, 0.95)' },
    { color:'rgba(75, 76, 79, 0.4)', shallow:'rgba(128, 129, 134, 0.95)' }
  ],
  ken: [
    { color:'rgba(191, 80, 63, 0.4)', shallow:'rgba(255, 136, 107, 0.95)' },
    { color:'rgba(28, 21, 21, 0.4)', shallow:'rgba(48, 36, 36, 0.95)' },
    { color:'rgba(99, 83, 151, 0.4)', shallow:'rgba(168, 141, 255, 0.95)' },
    { color:'rgba(171, 150, 137, 0.4)', shallow:'rgba(255, 255, 233, 0.95)' },
    { color:'rgba(108, 131, 98, 0.4)', shallow:'rgba(184, 223, 167, 0.95)' },
    { color:'rgba(181, 147, 81, 0.4)', shallow:'rgba(255, 250, 138, 0.95)' },
    { color:'rgba(68, 118, 129, 0.4)', shallow:'rgba(116, 201, 219, 0.95)' },
    { color:'rgba(22, 19, 18, 0.4)', shallow:'rgba(37, 32, 31, 0.95)' },
  ],
  kirby: [
    { color:'rgba(240, 99, 126, 0.4)', shallow:'rgba(255, 168, 214, 0.95)' },
    { color:'rgba(245, 215, 96, 0.4)', shallow:'rgba(255, 255, 163, 0.95)' },
    { color:'rgba(87, 143, 226, 0.4)', shallow:'rgba(148, 243, 255, 0.95)' },
    { color:'rgba(194, 62, 80, 0.4)', shallow:'rgba(255, 105, 136, 0.95)' },
    { color:'rgba(136, 219, 116, 0.4)', shallow:'rgba(231, 255, 197, 0.95)' },
    { color:'rgba(178, 177, 176, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(251, 164, 140, 0.4)', shallow:'rgba(255, 255, 238, 0.95)' },
    { color:'rgba(65, 59, 100, 0.4)', shallow:'rgba(111, 100, 170, 0.95)' }
  ],
  koopa: [
    { color:'rgba(117, 105, 70, 0.4)', shallow:'rgba(199, 179, 119, 0.95)' },
    { color:'rgba(108, 66, 41, 0.4)', shallow:'rgba(184, 112, 70, 0.95)' },
    { color:'rgba(218, 180, 84, 0.4)', shallow:'rgba(255, 255, 143, 0.95)' },
    { color:'rgba(106, 139, 76, 0.4)', shallow:'rgba(180, 236, 129, 0.95)' },
    { color:'rgba(79, 74, 75, 0.4)', shallow:'rgba(134, 126, 128, 0.95)' },
    { color:'rgba(197, 137, 79, 0.4)', shallow:'rgba(255, 233, 134, 0.95)' },
    { color:'rgba(193, 83, 75, 0.4)', shallow:'rgba(255, 141, 128, 0.95)' },
    { color:'rgba(66, 75, 106, 0.4)', shallow:'rgba(112, 128, 180, 0.95)' }
  ],
  koopajr: [
    { color:'rgba(182, 169, 86, 0.4)', shallow:'rgba(255, 255, 146, 0.95)' },
    { color:'rgba(67, 111, 178, 0.4)', shallow:'rgba(114, 189, 255, 0.95)' },
    { color:'rgba(185, 71, 66, 0.4)', shallow:'rgba(255, 121, 112, 0.95)' },
    { color:'rgba(245, 158, 204, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(132, 175, 50, 0.4)', shallow:'rgba(224, 255, 85, 0.95)' },
    { color:'rgba(95, 68, 68, 0.4)', shallow:'rgba(162, 116, 116, 0.95)' },
    { color:'rgba(195, 161, 44, 0.4)', shallow:'rgba(255, 255, 75, 0.95)' },
    { color:'rgba(109, 119, 188, 0.4)', shallow:'rgba(185, 202, 255, 0.95)' }
  ],
  krool: [
    { color:'rgba(107, 135, 90, 0.4)', shallow:'rgba(182, 230, 153, 0.95)' },
    { color:'rgba(93, 47, 32, 0.4)', shallow:'rgba(158, 80, 54, 0.95)' },
    { color:'rgba(64, 102, 117, 0.4)', shallow:'rgba(109, 173, 199, 0.95)' },
    { color:'rgba(105, 90, 27, 0.4)', shallow:'rgba(179, 153, 46, 0.95)' },
    { color:'rgba(113, 72, 72, 0.4)', shallow:'rgba(192, 122, 122, 0.95)' },
    { color:'rgba(39, 28, 32, 0.4)', shallow:'rgba(66, 48, 54, 0.95)' },
    { color:'rgba(48, 31, 25, 0.4)', shallow:'rgba(82, 53, 43, 0.95)' },
    { color:'rgba(140, 140, 190, 0.4)', shallow:'rgba(238, 238, 255, 0.95)' }
  ],
  link: [
    { color:'rgba(104, 137, 147, 0.4)', shallow:'rgba(177, 233, 250, 0.95)' },
    { color:'rgba(118, 142, 100, 0.4)', shallow:'rgba(201, 241, 170, 0.95)' },
    { color:'rgba(98, 45, 33, 0.4)', shallow:'rgba(167, 77, 56, 0.95)' },
    { color:'rgba(61, 75, 74, 0.4)', shallow:'rgba(104, 128, 126, 0.95)' },
    { color:'rgba(77, 84, 94, 0.4)', shallow:'rgba(131, 143, 160, 0.95)' },
    { color:'rgba(120, 139, 129, 0.4)', shallow:'rgba(204, 236, 219, 0.95)' },
    { color:'rgba(119, 114, 104, 0.4)', shallow:'rgba(202, 194, 177, 0.95)' },
    { color:'rgba(36, 33, 36, 0.4)', shallow:'rgba(61, 56, 61, 0.95)' }
  ],
  littlemac: [
    { color:'rgba(30, 37, 24, 0.4)', shallow:'rgba(51, 63, 41, 0.95)' },
    { color:'rgba(164, 112, 51, 0.4)', shallow:'rgba(255, 190, 87, 0.95)' },
    { color:'rgba(147, 127, 113, 0.4)', shallow:'rgba(250, 216, 192, 0.95)' },
    { color:'rgba(34, 27, 66, 0.4)', shallow:'rgba(58, 46, 112, 0.95)' },
    { color:'rgba(203, 158, 124, 0.4)', shallow:'rgba(255, 255, 211, 0.95)' },
    { color:'rgba(145, 83, 99, 0.4)', shallow:'rgba(247, 141, 168, 0.95)' },
    { color:'rgba(20, 31, 16, 0.4)', shallow:'rgba(34, 53, 27, 0.95)' },
    { color:'rgba(54, 101, 36, 0.4)', shallow:'rgba(92, 172, 61, 0.95)' }
  ],
  lucario: [
    { color:'rgba(39, 89, 140, 0.4)', shallow:'rgba(66, 151, 238, 0.95)' },
    { color:'rgba(118, 73, 129, 0.4)', shallow:'rgba(201, 124, 219, 0.95)' },
    { color:'rgba(91, 118, 141, 0.4)', shallow:'rgba(155, 201, 240, 0.95)' },
    { color:'rgba(137, 134, 139, 0.4)', shallow:'rgba(233, 228, 236, 0.95)' },
    { color:'rgba(78, 144, 140, 0.4)', shallow:'rgba(133, 245, 238, 0.95)' },
    { color:'rgba(103, 122, 193, 0.4)', shallow:'rgba(175, 207, 255, 0.95)' },
    { color:'rgba(57, 104, 47, 0.4)', shallow:'rgba(97, 177, 80, 0.95)' },
    { color:'rgba(159, 91, 132, 0.4)', shallow:'rgba(255, 155, 224, 0.95)' }
  ],
  lucas: [
    { color:'rgba(222, 162, 107, 0.4)', shallow:'rgba(255, 255, 182, 0.95)' },
    { color:'rgba(225, 151, 103, 0.4)', shallow:'rgba(255, 255, 175, 0.95)' },
    { color:'rgba(124, 104, 139, 0.4)', shallow:'rgba(211, 177, 236, 0.95)' },
    { color:'rgba(174, 59, 86, 0.4)', shallow:'rgba(255, 100, 146, 0.95)' },
    { color:'rgba(109, 111, 108, 0.4)', shallow:'rgba(185, 189, 184, 0.95)' },
    { color:'rgba(84, 116, 220, 0.4)', shallow:'rgba(143, 197, 255, 0.95)' },
    { color:'rgba(58, 156, 126, 0.4)', shallow:'rgba(99, 255, 214, 0.95)' },
    { color:'rgba(136, 98, 141, 0.4)', shallow:'rgba(231, 167, 240, 0.95)' }
  ],
  lucina: [
    { color:'rgba(85, 96, 125, 0.4)', shallow:'rgba(145, 163, 213, 0.95)' },
    { color:'rgba(145, 143, 123, 0.4)', shallow:'rgba(247, 243, 209, 0.95)' },
    { color:'rgba(110, 110, 118, 0.4)', shallow:'rgba(187, 187, 201, 0.95)' },
    { color:'rgba(155, 107, 99, 0.4)', shallow:'rgba(255, 182, 168, 0.95)' },
    { color:'rgba(85, 19, 17, 0.4)', shallow:'rgba(145, 32, 29, 0.95)' },
    { color:'rgba(150, 127, 84, 0.4)', shallow:'rgba(255, 216, 143, 0.95)' },
    { color:'rgba(36, 20, 30, 0.4)', shallow:'rgba(61, 34, 51, 0.95)' },
    { color:'rgba(41, 37, 42, 0.4)', shallow:'rgba(70, 63, 71, 0.95)' }
  ],
  luigi: [
    { color:'rgba(105, 189, 76, 0.4)', shallow:'rgba(179, 255, 129, 0.95)' },
    { color:'rgba(177, 101, 33, 0.4)', shallow:'rgba(255, 172, 56, 0.95)' },
    { color:'rgba(164, 93, 110, 0.4)', shallow:'rgba(255, 158, 187, 0.95)' },
    { color:'rgba(116, 197, 202, 0.4)', shallow:'rgba(197, 255, 255, 0.95)' },
    { color:'rgba(107, 130, 111, 0.4)', shallow:'rgba(182, 221, 189, 0.95)' },
    { color:'rgba(31, 21, 48, 0.4)', shallow:'rgba(53, 36, 82, 0.95)' },
    { color:'rgba(47, 54, 58, 0.4)', shallow:'rgba(80, 92, 99, 0.95)' },
    { color:'rgba(32, 37, 25, 0.4)', shallow:'rgba(54, 63, 43, 0.95)' }
  ],
  mario: [
    { color:'rgba(134, 22, 22, 0.4)', shallow:'rgba(228, 37, 37, 0.95)' },
    { color:'rgba(195, 66, 63, 0.4)', shallow:'rgba(255, 112, 107, 0.95)' },
    { color:'rgba(54, 112, 134, 0.4)', shallow:'rgba(92, 190, 228, 0.95)' },
    { color:'rgba(81, 72, 93, 0.4)', shallow:'rgba(138, 122, 158, 0.95)' },
    { color:'rgba(138, 181, 128, 0.4)', shallow:'rgba(235, 255, 218, 0.95)' },
    { color:'rgba(27, 21, 63, 0.4)', shallow:'rgba(46, 36, 107, 0.95)' },
    { color:'rgba(237, 152, 64, 0.4)', shallow:'rgba(255, 255, 109, 0.95)' },
    { color:'rgba(180, 173, 168, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' }
  ],
  mariod: [
    { color:'rgba(177, 175, 183, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(203, 59, 54, 0.4)', shallow:'rgba(255, 100, 92, 0.95)' },
    { color:'rgba(102, 112, 131, 0.4)', shallow:'rgba(173, 190, 223, 0.95)' },
    { color:'rgba(107, 126, 103, 0.4)', shallow:'rgba(182, 214, 175, 0.95)' },
    { color:'rgba(78, 72, 71, 0.4)', shallow:'rgba(133, 122, 121, 0.95)' },
    { color:'rgba(239, 203, 83, 0.4)', shallow:'rgba(255, 255, 141, 0.95)' },
    { color:'rgba(112, 100, 128, 0.4)', shallow:'rgba(190, 170, 218, 0.95)' },
    { color:'rgba(142, 99, 122, 0.4)', shallow:'rgba(241, 168, 207, 0.95)' }
  ],
  marth: [
    { color:'rgba(102, 125, 173, 0.4)', shallow:'rgba(173, 213, 255, 0.95)' },
    { color:'rgba(186, 69, 77, 0.4)', shallow:'rgba(255, 117, 131, 0.95)' },
    { color:'rgba(64, 99, 71, 0.4)', shallow:'rgba(109, 168, 121, 0.95)' },
    { color:'rgba(160, 112, 34, 0.4)', shallow:'rgba(255, 190, 58, 0.95)' },
    { color:'rgba(159, 152, 139, 0.4)', shallow:'rgba(255, 255, 236, 0.95)' },
    { color:'rgba(114, 127, 194, 0.4)', shallow:'rgba(194, 216, 255, 0.95)' },
    { color:'rgba(217, 135, 57, 0.4)', shallow:'rgba(255, 230, 97, 0.95)' },
    { color:'rgba(99, 99, 147, 0.4)', shallow:'rgba(168, 168, 250, 0.95)' }
  ],
  master: [
    { color:'rgba(93, 119, 134, 0.4)', shallow:'rgba(158, 202, 228, 0.95)' },
    { color:'rgba(110, 128, 148, 0.4)', shallow:'rgba(187, 218, 252, 0.95)' },
    { color:'rgba(83, 89, 186, 0.4)', shallow:'rgba(141, 151, 255, 0.95)' },
    { color:'rgba(169, 53, 46, 0.4)', shallow:'rgba(255, 90, 78, 0.95)' },
    { color:'rgba(208, 175, 69, 0.4)', shallow:'rgba(255, 255, 117, 0.95)' },
    { color:'rgba(95, 146, 108, 0.4)', shallow:'rgba(162, 248, 184, 0.95)' },
    { color:'rgba(97, 119, 94, 0.4)', shallow:'rgba(165, 202, 160, 0.95)' },
    { color:'rgba(110, 129, 111, 0.4)', shallow:'rgba(187, 219, 189, 0.95)' }
  ],
  metaknight: [
    { color:'rgba(23, 26, 68, 0.4)', shallow:'rgba(39, 44, 116, 0.95)' },
    { color:'rgba(141, 137, 103, 0.4)', shallow:'rgba(240, 233, 175, 0.95)' },
    { color:'rgba(89, 26, 20, 0.4)', shallow:'rgba(151, 44, 34, 0.95)' },
    { color:'rgba(101, 140, 69, 0.4)', shallow:'rgba(172, 238, 117, 0.95)' },
    { color:'rgba(121, 38, 30, 0.4)', shallow:'rgba(206, 65, 51, 0.95)' },
    { color:'rgba(150, 51, 65, 0.4)', shallow:'rgba(255, 87, 111, 0.95)' },
    { color:'rgba(184, 183, 194, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(50, 46, 51, 0.4)', shallow:'rgba(85, 78, 87, 0.95)' }
  ],
  mewtwo: [
    { color:'rgba(132, 90, 123, 0.4)', shallow:'rgba(224, 153, 209, 0.95)' },
    { color:'rgba(164, 99, 50, 0.4)', shallow:'rgba(255, 168, 85, 0.95)' },
    { color:'rgba(55, 75, 131, 0.4)', shallow:'rgba(94, 128, 223, 0.95)' },
    { color:'rgba(94, 63, 52, 0.4)', shallow:'rgba(160, 107, 88, 0.95)' },
    { color:'rgba(119, 54, 43, 0.4)', shallow:'rgba(202, 92, 73, 0.95)' },
    { color:'rgba(158, 157, 83, 0.4)', shallow:'rgba(255, 255, 141, 0.95)' },
    { color:'rgba(65, 130, 125, 0.4)', shallow:'rgba(111, 221, 213, 0.95)' },
    { color:'rgba(37, 28, 32, 0.4)', shallow:'rgba(63, 48, 54, 0.95)' }
  ],
  miifighter: [
    { color:'rgba(203, 67, 63, 0.4)', shallow:'rgba(255, 114, 107, 0.95)' }
  ],
  miiswordsman: [
    { color:'rgba(68, 92, 180, 0.4)', shallow:'rgba(116, 156, 255, 0.95)' }
  ],
  miigunner: [
    { color:'rgba(222, 161, 65, 0.4)', shallow:'rgba(255, 255, 111, 0.95)' }
  ],
  murabito: [
    { color:'rgba(205, 56, 49, 0.4)', shallow:'rgba(255, 95, 83, 0.95)' },
    { color:'rgba(236, 109, 132, 0.4)', shallow:'rgba(255, 185, 224, 0.95)' },
    { color:'rgba(219, 167, 105, 0.4)', shallow:'rgba(255, 255, 179, 0.95)' },
    { color:'rgba(69, 77, 50, 0.4)', shallow:'rgba(117, 131, 85, 0.95)' },
    { color:'rgba(90, 185, 204, 0.4)', shallow:'rgba(153, 255, 255, 0.95)' },
    { color:'rgba(100, 132, 218, 0.4)', shallow:'rgba(170, 224, 255, 0.95)' },
    { color:'rgba(52, 36, 43, 0.4)', shallow:'rgba(88, 61, 73, 0.95)' },
    { color:'rgba(209, 225, 117, 0.4)', shallow:'rgba(255, 255, 199, 0.95)' }
  ],
  ness: [
    { color:'rgba(245, 222, 98, 0.4)', shallow:'rgba(255, 255, 167, 0.95)' },
    { color:'rgba(207, 156, 117, 0.4)', shallow:'rgba(255, 255, 199, 0.95)' },
    { color:'rgba(175, 132, 51, 0.4)', shallow:'rgba(255, 224, 87, 0.95)' },
    { color:'rgba(91, 196, 83, 0.4)', shallow:'rgba(155, 255, 141, 0.95)' },
    { color:'rgba(61, 53, 85, 0.4)', shallow:'rgba(104, 90, 145, 0.95)' },
    { color:'rgba(55, 47, 61, 0.4)', shallow:'rgba(94, 80, 104, 0.95)' },
    { color:'rgba(50, 94, 96, 0.4)', shallow:'rgba(85, 160, 163, 0.95)' },
    { color:'rgba(126, 207, 182, 0.4)', shallow:'rgba(214, 255, 255, 0.95)' }
  ],
  packun: [
    { color:'rgba(228, 72, 66, 0.4)', shallow:'rgba(255, 122, 112, 0.95)' },
    { color:'rgba(118, 157, 81, 0.4)', shallow:'rgba(201, 255, 138, 0.95)' },
    { color:'rgba(168, 130, 52, 0.4)', shallow:'rgba(255, 221, 88, 0.95)' },
    { color:'rgba(62, 34, 61, 0.4)', shallow:'rgba(105, 58, 104, 0.95)' },
    { color:'rgba(80, 48, 29, 0.4)', shallow:'rgba(136, 82, 49, 0.95)' },
    { color:'rgba(166, 184, 161, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(97, 40, 98, 0.4)', shallow:'rgba(165, 68, 167, 0.95)' },
    { color:'rgba(63, 179, 252, 0.4)', shallow:'rgba(107, 255, 255, 0.95)' }
  ],
  pacman: [
    { color:'rgba(243, 220, 94, 0.4)', shallow:'rgba(255, 255, 160, 0.95)' },
    { color:'rgba(74, 96, 208, 0.4)', shallow:'rgba(126, 163, 255, 0.95)' },
    { color:'rgba(241, 215, 87, 0.4)', shallow:'rgba(255, 255, 148, 0.95)' },
    { color:'rgba(29, 23, 25, 0.4)', shallow:'rgba(49, 39, 43, 0.95)' },
    { color:'rgba(211, 97, 213, 0.4)', shallow:'rgba(255, 165, 255, 0.95)' },
    { color:'rgba(218, 215, 214, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(158, 38, 34, 0.4)', shallow:'rgba(255, 65, 58, 0.95)' },
    { color:'rgba(242, 216, 90, 0.4)', shallow:'rgba(255, 255, 153, 0.95)' }
  ],
  palutena: [
    { color:'rgba(231, 230, 205, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(170, 120, 118, 0.4)', shallow:'rgba(255, 204, 201, 0.95)' },
    { color:'rgba(174, 110, 177, 0.4)', shallow:'rgba(255, 187, 255, 0.95)' },
    { color:'rgba(146, 213, 135, 0.4)', shallow:'rgba(248, 255, 230, 0.95)' },
    { color:'rgba(72, 101, 187, 0.4)', shallow:'rgba(122, 172, 255, 0.95)' },
    { color:'rgba(196, 85, 97, 0.4)', shallow:'rgba(255, 145, 165, 0.95)' },
    { color:'rgba(25, 20, 23, 0.4)', shallow:'rgba(43, 34, 39, 0.95)' },
    { color:'rgba(60, 37, 26, 0.4)', shallow:'rgba(102, 63, 44, 0.95)' }
  ],
  peach: [
    { color:'rgba(228, 104, 144, 0.4)', shallow:'rgba(255, 177, 245, 0.95)' },
    { color:'rgba(248, 227, 63, 0.4)', shallow:'rgba(255, 255, 107, 0.95)' },
    { color:'rgba(188, 58, 70, 0.4)', shallow:'rgba(255, 99, 119, 0.95)' },
    { color:'rgba(98, 98, 217, 0.4)', shallow:'rgba(167, 167, 255, 0.95)' },
    { color:'rgba(106, 174, 89, 0.4)', shallow:'rgba(180, 255, 151, 0.95)' },
    { color:'rgba(229, 226, 226, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(227, 68, 57, 0.4)', shallow:'rgba(255, 116, 97, 0.95)' },
    { color:'rgba(31, 30, 30, 0.4)', shallow:'rgba(53, 51, 51, 0.95)' }
  ],
  pichu: [
    { color:'rgba(247, 234, 101, 0.4)', shallow:'rgba(255, 255, 172, 0.95)' },
    { color:'rgba(57, 87, 171, 0.4)', shallow:'rgba(97, 148, 255, 0.95)' },
    { color:'rgba(206, 73, 72, 0.4)', shallow:'rgba(255, 124, 122, 0.95)' },
    { color:'rgba(212, 190, 171, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(95, 119, 194, 0.4)', shallow:'rgba(162, 202, 255, 0.95)' },
    { color:'rgba(248, 234, 101, 0.4)', shallow:'rgba(255, 255, 172, 0.95)' },
    { color:'rgba(160, 158, 129, 0.4)', shallow:'rgba(255, 255, 219, 0.95)' },
    { color:'rgba(196, 159, 29, 0.4)', shallow:'rgba(255, 255, 49, 0.95)' }
  ],
  pickel: [
    { color:'rgba(21, 185, 185, 0.4)', shallow:'rgba(36, 255, 255, 0.95)' },
    { color:'rgba(103, 136, 81, 0.4)', shallow:'rgba(175, 231, 138, 0.95)' },
    { color:'rgba(208, 167, 146, 0.4)', shallow:'rgba(255, 255, 248, 0.95)' },
    { color:'rgba(142, 87, 40, 0.4)', shallow:'rgba(241, 148, 68, 0.95)' },
    { color:'rgba(92, 50, 16, 0.4)', shallow:'rgba(156, 85, 27, 0.95)' },
    { color:'rgba(219, 191, 21, 0.4)', shallow:'rgba(255, 255, 36, 0.95)' },
    { color:'rgba(101, 138, 78, 0.4)', shallow:'rgba(172, 235, 133, 0.95)' },
    { color:'rgba(28, 27, 28, 0.4)', shallow:'rgba(48, 46, 48, 0.95)' }
  ],
  pikachu: [
    { color:'rgba(245, 218, 68, 0.4)', shallow:'rgba(255, 255, 116, 0.95)' },
    { color:'rgba(222, 126, 70, 0.4)', shallow:'rgba(255, 214, 119, 0.95)' },
    { color:'rgba(236, 232, 85, 0.4)', shallow:'rgba(255, 255, 145, 0.95)' },
    { color:'rgba(161, 143, 93, 0.4)', shallow:'rgba(255, 243, 158, 0.95)' },
    { color:'rgba(116, 116, 160, 0.4)', shallow:'rgba(197, 197, 255, 0.95)' },
    { color:'rgba(197, 66, 45, 0.4)', shallow:'rgba(255, 112, 77, 0.95)' },
    { color:'rgba(213, 66, 54, 0.4)', shallow:'rgba(255, 112, 92, 0.95)' },
    { color:'rgba(225, 75, 52, 0.4)', shallow:'rgba(255, 128, 88, 0.95)' }
  ],
  pikmin: [
    { color:'rgba(241, 200, 115, 0.4)', shallow:'rgba(255, 255, 196, 0.95)' },
    { color:'rgba(197, 58, 53, 0.4)', shallow:'rgba(255, 99, 90, 0.95)' },
    { color:'rgba(56, 93, 47, 0.4)', shallow:'rgba(95, 158, 80, 0.95)' },
    { color:'rgba(111, 111, 118, 0.4)', shallow:'rgba(189, 189, 201, 0.95)' },
    { color:'rgba(45, 108, 193, 0.4)', shallow:'rgba(77, 184, 255, 0.95)' },
    { color:'rgba(53, 94, 41, 0.4)', shallow:'rgba(90, 160, 70, 0.95)' },
    { color:'rgba(193, 108, 115, 0.4)', shallow:'rgba(255, 184, 196, 0.95)' },
    { color:'rgba(202, 59, 47, 0.4)', shallow:'rgba(255, 100, 80, 0.95)' }
  ],
  pit: [
    { color:'rgba(129, 136, 162, 0.4)', shallow:'rgba(219, 231, 255, 0.95)' },
    { color:'rgba(195, 176, 99, 0.4)', shallow:'rgba(255, 255, 168, 0.95)' },
    { color:'rgba(175, 129, 142, 0.4)', shallow:'rgba(255, 219, 241, 0.95)' },
    { color:'rgba(97, 128, 64, 0.4)', shallow:'rgba(165, 218, 109, 0.95)' },
    { color:'rgba(115, 149, 205, 0.4)', shallow:'rgba(196, 253, 255, 0.95)' },
    { color:'rgba(72, 67, 66, 0.4)', shallow:'rgba(122, 114, 112, 0.95)' },
    { color:'rgba(201, 172, 112, 0.4)', shallow:'rgba(255, 255, 190, 0.95)' },
    { color:'rgba(225, 136, 160, 0.4)', shallow:'rgba(255, 231, 255, 0.95)' }
  ],
  pitb: [
    { color:'rgba(28, 27, 30, 0.4)', shallow:'rgba(48, 46, 51, 0.95)' },
    { color:'rgba(79, 125, 99, 0.4)', shallow:'rgba(134, 213, 168, 0.95)' },
    { color:'rgba(85, 62, 57, 0.4)', shallow:'rgba(145, 105, 97, 0.95)' },
    { color:'rgba(211, 168, 82, 0.4)', shallow:'rgba(255, 255, 139, 0.95)' },
    { color:'rgba(99, 38, 46, 0.4)', shallow:'rgba(168, 65, 78, 0.95)' },
    { color:'rgba(62, 56, 115, 0.4)', shallow:'rgba(105, 95, 196, 0.95)' },
    { color:'rgba(155, 171, 167, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(24, 18, 40, 0.4)', shallow:'rgba(41, 31, 68, 0.95)' }
  ],
  plizardon: [
    { color:'rgba(218, 117, 73, 0.4)', shallow:'rgba(255, 199, 124, 0.95)' },
    { color:'rgba(195, 91, 91, 0.4)', shallow:'rgba(255, 155, 155, 0.95)' },
    { color:'rgba(82, 108, 50, 0.4)', shallow:'rgba(139, 184, 85, 0.95)' },
    { color:'rgba(167, 87, 122, 0.4)', shallow:'rgba(255, 148, 207, 0.95)' },
    { color:'rgba(193, 152, 76, 0.4)', shallow:'rgba(255, 255, 129, 0.95)' },
    { color:'rgba(167, 160, 169, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(103, 128, 189, 0.4)', shallow:'rgba(175, 218, 255, 0.95)' },
    { color:'rgba(158, 120, 192, 0.4)', shallow:'rgba(255, 204, 255, 0.95)' }
  ],
  ptrainer: [
    { color:'rgba(203, 74, 76, 0.4)', shallow:'rgba(255, 126, 129, 0.95)' },
    { color:'rgba(140, 208, 212, 0.4)', shallow:'rgba(238, 255, 255, 0.95)' },
    { color:'rgba(203, 159, 87, 0.4)', shallow:'rgba(255, 255, 148, 0.95)' },
    { color:'rgba(105, 176, 122, 0.4)', shallow:'rgba(179, 255, 207, 0.95)' },
    { color:'rgba(56, 48, 66, 0.4)', shallow:'rgba(95, 82, 112, 0.95)' },
    { color:'rgba(239, 206, 185, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(102, 122, 187, 0.4)', shallow:'rgba(173, 207, 255, 0.95)' },
    { color:'rgba(179, 99, 38, 0.4)', shallow:'rgba(255, 168, 65, 0.95)' }
  ],
  purin: [
    { color:'rgba(246, 198, 206, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(237, 67, 68, 0.4)', shallow:'rgba(255, 114, 116, 0.95)' },
    { color:'rgba(238, 133, 132, 0.4)', shallow:'rgba(255, 226, 224, 0.95)' },
    { color:'rgba(235, 161, 200, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(252, 140, 132, 0.4)', shallow:'rgba(255, 238, 224, 0.95)' },
    { color:'rgba(153, 43, 79, 0.4)', shallow:'rgba(255, 73, 134, 0.95)' },
    { color:'rgba(248, 213, 215, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(95, 21, 19, 0.4)', shallow:'rgba(162, 36, 32, 0.95)' }
  ],
  reflet: [
    { color:'rgba(20, 15, 18, 0.4)', shallow:'rgba(34, 26, 31, 0.95)' },
    { color:'rgba(76, 68, 76, 0.4)', shallow:'rgba(129, 116, 129, 0.95)' },
    { color:'rgba(45, 102, 30, 0.4)', shallow:'rgba(77, 173, 51, 0.95)' },
    { color:'rgba(222, 92, 85, 0.4)', shallow:'rgba(255, 156, 145, 0.95)' },
    { color:'rgba(91, 108, 145, 0.4)', shallow:'rgba(155, 184, 247, 0.95)' },
    { color:'rgba(226, 176, 44, 0.4)', shallow:'rgba(255, 255, 75, 0.95)' },
    { color:'rgba(122, 118, 122, 0.4)', shallow:'rgba(207, 201, 207, 0.95)' },
    { color:'rgba(237, 181, 174, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' }
  ],
  richter: [
    { color:'rgba(73, 87, 137, 0.4)', shallow:'rgba(124, 148, 233, 0.95)' },
    { color:'rgba(156, 95, 88, 0.4)', shallow:'rgba(255, 162, 150, 0.95)' },
    { color:'rgba(104, 83, 123, 0.4)', shallow:'rgba(177, 141, 209, 0.95)' },
    { color:'rgba(79, 119, 87, 0.4)', shallow:'rgba(134, 202, 148, 0.95)' },
    { color:'rgba(177, 140, 57, 0.4)', shallow:'rgba(255, 238, 97, 0.95)' },
    { color:'rgba(74, 100, 188, 0.4)', shallow:'rgba(126, 170, 255, 0.95)' },
    { color:'rgba(35, 27, 24, 0.4)', shallow:'rgba(60, 46, 41, 0.95)' },
    { color:'rgba(166, 150, 145, 0.4)', shallow:'rgba(255, 255, 247, 0.95)' }
  ],
  ridley: [
    { color:'rgba(96, 78, 99, 0.4)', shallow:'rgba(163, 133, 168, 0.95)' },
    { color:'rgba(117, 98, 131, 0.4)', shallow:'rgba(199, 167, 223, 0.95)' },
    { color:'rgba(183, 78, 64, 0.4)', shallow:'rgba(255, 133, 109, 0.95)' },
    { color:'rgba(95, 106, 141, 0.4)', shallow:'rgba(162, 180, 240, 0.95)' },
    { color:'rgba(102, 140, 89, 0.4)', shallow:'rgba(173, 238, 151, 0.95)' },
    { color:'rgba(95, 55, 112, 0.4)', shallow:'rgba(162, 94, 190, 0.95)' },
    { color:'rgba(239, 222, 111, 0.4)', shallow:'rgba(255, 255, 189, 0.95)' },
    { color:'rgba(151, 151, 138, 0.4)', shallow:'rgba(255, 255, 235, 0.95)' }
  ],
  robot: [
    { color:'rgba(171, 167, 152, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(139, 135, 140, 0.4)', shallow:'rgba(236, 230, 238, 0.95)' },
    { color:'rgba(207, 160, 77, 0.4)', shallow:'rgba(255, 255, 131, 0.95)' },
    { color:'rgba(168, 130, 153, 0.4)', shallow:'rgba(255, 221, 255, 0.95)' },
    { color:'rgba(122, 179, 196, 0.4)', shallow:'rgba(207, 255, 255, 0.95)' },
    { color:'rgba(92, 103, 55, 0.4)', shallow:'rgba(156, 175, 94, 0.95)' },
    { color:'rgba(139, 176, 183, 0.4)', shallow:'rgba(236, 255, 255, 0.95)' },
    { color:'rgba(132, 56, 47, 0.4)', shallow:'rgba(224, 95, 80, 0.95)' }
  ],
  rockman: [
    { color:'rgba(81, 136, 221, 0.4)', shallow:'rgba(138, 231, 255, 0.95)' },
    { color:'rgba(212, 71, 54, 0.4)', shallow:'rgba(255, 121, 92, 0.95)' },
    { color:'rgba(83, 207, 116, 0.4)', shallow:'rgba(141, 255, 197, 0.95)' },
    { color:'rgba(234, 207, 115, 0.4)', shallow:'rgba(255, 255, 196, 0.95)' },
    { color:'rgba(85, 184, 232, 0.4)', shallow:'rgba(145, 255, 255, 0.95)' },
    { color:'rgba(110, 221, 109, 0.4)', shallow:'rgba(187, 255, 185, 0.95)' },
    { color:'rgba(215, 89, 16, 0.4)', shallow:'rgba(255, 151, 27, 0.95)' },
    { color:'rgba(37, 36, 36, 0.4)', shallow:'rgba(63, 61, 61, 0.95)' }
  ],
  rosetta: [
    { color:'rgba(111, 223, 219, 0.4)', shallow:'rgba(189, 255, 255, 0.95)' },
    { color:'rgba(238, 148, 171, 0.4)', shallow:'rgba(255, 252, 255, 0.95)' },
    { color:'rgba(222, 180, 115, 0.4)', shallow:'rgba(255, 255, 196, 0.95)' },
    { color:'rgba(105, 211, 138, 0.4)', shallow:'rgba(179, 255, 235, 0.95)' },
    { color:'rgba(117, 13, 12, 0.4)', shallow:'rgba(199, 22, 20, 0.95)' },
    { color:'rgba(188, 125, 221, 0.4)', shallow:'rgba(255, 213, 255, 0.95)' },
    { color:'rgba(44, 43, 43, 0.4)', shallow:'rgba(75, 73, 73, 0.95)' },
    { color:'rgba(155, 130, 135, 0.4)', shallow:'rgba(255, 221, 230, 0.95)' }
  ],
  roy: [
    { color:'rgba(64, 67, 117, 0.4)', shallow:'rgba(109, 114, 199, 0.95)' },
    { color:'rgba(207, 72, 51, 0.4)', shallow:'rgba(255, 122, 87, 0.95)' },
    { color:'rgba(48, 114, 97, 0.4)', shallow:'rgba(82, 194, 165, 0.95)' },
    { color:'rgba(53, 28, 42, 0.4)', shallow:'rgba(90, 48, 71, 0.95)' },
    { color:'rgba(67, 127, 166, 0.4)', shallow:'rgba(114, 216, 255, 0.95)' },
    { color:'rgba(30, 22, 43, 0.4)', shallow:'rgba(51, 37, 73, 0.95)' },
    { color:'rgba(112, 85, 119, 0.4)', shallow:'rgba(190, 145, 202, 0.95)' },
    { color:'rgba(225, 175, 93, 0.4)', shallow:'rgba(255, 255, 158, 0.95)' }
  ],
  ryu: [
    { color:'rgba(202, 166, 141, 0.4)', shallow:'rgba(255, 255, 240, 0.95)' },
    { color:'rgba(93, 91, 96, 0.4)', shallow:'rgba(158, 155, 163, 0.95)' },
    { color:'rgba(213, 226, 231, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(39, 31, 35, 0.4)', shallow:'rgba(66, 53, 60, 0.95)' },
    { color:'rgba(185, 131, 94, 0.4)', shallow:'rgba(255, 223, 160, 0.95)' },
    { color:'rgba(60, 92, 156, 0.4)', shallow:'rgba(102, 156, 255, 0.95)' },
    { color:'rgba(209, 170, 117, 0.4)', shallow:'rgba(255, 255, 199, 0.95)' },
    { color:'rgba(122, 90, 104, 0.4)', shallow:'rgba(207, 153, 177, 0.95)' }
  ],
  samus: [
    { color:'rgba(186, 107, 52, 0.4)', shallow:'rgba(255, 182, 88, 0.95)' },
    { color:'rgba(114, 216, 214, 0.4)', shallow:'rgba(194, 255, 255, 0.95)' },
    { color:'rgba(196, 97, 123, 0.4)', shallow:'rgba(255, 165, 209, 0.95)' },
    { color:'rgba(46, 26, 22, 0.4)', shallow:'rgba(78, 44, 37, 0.95)' },
    { color:'rgba(164, 97, 191, 0.4)', shallow:'rgba(255, 165, 255, 0.95)' },
    { color:'rgba(121, 203, 84, 0.4)', shallow:'rgba(206, 255, 143, 0.95)' },
    { color:'rgba(158, 157, 153, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(30, 28, 35, 0.4)', shallow:'rgba(51, 48, 60, 0.95)' },
  ],
  samusd: [
    { color:'rgba(17, 17, 25, 0.4)', shallow:'rgba(29, 29, 43, 0.95)' },
    { color:'rgba(70, 101, 72, 0.4)', shallow:'rgba(119, 172, 122, 0.95)' },
    { color:'rgba(21, 16, 36, 0.4)', shallow:'rgba(36, 27, 61, 0.95)' },
    { color:'rgba(90, 45, 41, 0.4)', shallow:'rgba(153, 77, 70, 0.95)' },
    { color:'rgba(224, 186, 93, 0.4)', shallow:'rgba(255, 255, 158, 0.95)' },
    { color:'rgba(155, 181, 194, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(20, 16, 14, 0.4)', shallow:'rgba(34, 27, 24, 0.95)' },
    { color:'rgba(119, 36, 54, 0.4)', shallow:'rgba(202, 61, 92, 0.95)' }
  ],
  sheik: [
    { color:'rgba(133, 140, 173, 0.4)', shallow:'rgba(226, 238, 255, 0.95)' },
    { color:'rgba(114, 43, 56, 0.4)', shallow:'rgba(194, 73, 95, 0.95)' },
    { color:'rgba(75, 68, 112, 0.4)', shallow:'rgba(128, 116, 190, 0.95)' },
    { color:'rgba(95, 50, 96, 0.4)', shallow:'rgba(162, 85, 163, 0.95)' },
    { color:'rgba(143, 212, 122, 0.4)', shallow:'rgba(243, 255, 207, 0.95)' },
    { color:'rgba(95, 92, 92, 0.4)', shallow:'rgba(162, 156, 156, 0.95)' },
    { color:'rgba(174, 150, 64, 0.4)', shallow:'rgba(255, 255, 109, 0.95)' },
    { color:'rgba(114, 155, 171, 0.4)', shallow:'rgba(194, 255, 255, 0.95)' }
  ],
  shizue: [
    { color:'rgba(161, 180, 99, 0.4)', shallow:'rgba(255, 255, 168, 0.95)' },
    { color:'rgba(181, 141, 91, 0.4)', shallow:'rgba(255, 240, 155, 0.95)' },
    { color:'rgba(195, 124, 146, 0.4)', shallow:'rgba(255, 211, 248, 0.95)' },
    { color:'rgba(170, 63, 53, 0.4)', shallow:'rgba(255, 107, 90, 0.95)' },
    { color:'rgba(177, 129, 136, 0.4)', shallow:'rgba(255, 219, 231, 0.95)' },
    { color:'rgba(75, 105, 80, 0.4)', shallow:'rgba(128, 179, 136, 0.95)' },
    { color:'rgba(232, 211, 132, 0.4)', shallow:'rgba(255, 255, 224, 0.95)' },
    { color:'rgba(107, 181, 182, 0.4)', shallow:'rgba(182, 255, 255, 0.95)' }
  ],
  shulk: [
    { color:'rgba(110, 61, 65, 0.4)', shallow:'rgba(187, 104, 111, 0.95)' },
    { color:'rgba(154, 130, 117, 0.4)', shallow:'rgba(255, 221, 199, 0.95)' },
    { color:'rgba(54, 37, 37, 0.4)', shallow:'rgba(92, 63, 63, 0.95)' },
    { color:'rgba(160, 70, 50, 0.4)', shallow:'rgba(255, 119, 85, 0.95)' },
    { color:'rgba(75, 123, 175, 0.4)', shallow:'rgba(128, 209, 255, 0.95)' },
    { color:'rgba(148, 95, 120, 0.4)', shallow:'rgba(252, 162, 204, 0.95)' },
    { color:'rgba(210, 88, 59, 0.4)', shallow:'rgba(255, 150, 100, 0.95)' },
    { color:'rgba(232, 183, 139, 0.4)', shallow:'rgba(255, 255, 236, 0.95)' }
  ],
  simon: [
    { color:'rgba(43, 30, 24, 0.4)', shallow:'rgba(73, 51, 41, 0.95)' },
    { color:'rgba(146, 119, 95, 0.4)', shallow:'rgba(248, 202, 162, 0.95)' },
    { color:'rgba(187, 158, 49, 0.4)', shallow:'rgba(255, 255, 83, 0.95)' },
    { color:'rgba(138, 109, 102, 0.4)', shallow:'rgba(235, 185, 173, 0.95)' },
    { color:'rgba(85, 82, 113, 0.4)', shallow:'rgba(145, 139, 192, 0.95)' },
    { color:'rgba(108, 93, 113, 0.4)', shallow:'rgba(184, 158, 192, 0.95)' },
    { color:'rgba(138, 105, 87, 0.4)', shallow:'rgba(235, 179, 148, 0.95)' },
    { color:'rgba(90, 79, 105, 0.4)', shallow:'rgba(153, 134, 179, 0.95)' }
  ],
  snake: [
    { color:'rgba(134, 145, 158, 0.4)', shallow:'rgba(228, 247, 255, 0.95)' },
    { color:'rgba(19, 16, 17, 0.4)', shallow:'rgba(32, 27, 29, 0.95)' },
    { color:'rgba(81, 25, 20, 0.4)', shallow:'rgba(138, 43, 34, 0.95)' },
    { color:'rgba(144, 164, 177, 0.4)', shallow:'rgba(245, 255, 255, 0.95)' },
    { color:'rgba(151, 152, 131, 0.4)', shallow:'rgba(255, 255, 223, 0.95)' },
    { color:'rgba(159, 159, 165, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(103, 103, 77, 0.4)', shallow:'rgba(175, 175, 131, 0.95)' },
    { color:'rgba(79, 60, 48, 0.4)', shallow:'rgba(134, 102, 82, 0.95)' }
  ],
  sonic: [
    { color:'rgba(44, 55, 183, 0.4)', shallow:'rgba(75, 94, 255, 0.95)' },
    { color:'rgba(55, 53, 93, 0.4)', shallow:'rgba(94, 90, 158, 0.95)' },
    { color:'rgba(84, 131, 209, 0.4)', shallow:'rgba(143, 223, 255, 0.95)' },
    { color:'rgba(118, 119, 139, 0.4)', shallow:'rgba(201, 202, 236, 0.95)' },
    { color:'rgba(39, 35, 47, 0.4)', shallow:'rgba(66, 60, 80, 0.95)' },
    { color:'rgba(114, 139, 175, 0.4)', shallow:'rgba(194, 236, 255, 0.95)' },
    { color:'rgba(113, 133, 191, 0.4)', shallow:'rgba(192, 226, 255, 0.95)' },
    { color:'rgba(95, 78, 188, 0.4)', shallow:'rgba(162, 133, 255, 0.95)' }
  ],
  szerosuit: [
    { color:'rgba(117, 208, 238, 0.4)', shallow:'rgba(199, 255, 255, 0.95)' },
    { color:'rgba(72, 94, 149, 0.4)', shallow:'rgba(122, 160, 253, 0.95)' },
    { color:'rgba(129, 40, 58, 0.4)', shallow:'rgba(219, 68, 99, 0.95)' },
    { color:'rgba(41, 38, 39, 0.4)', shallow:'rgba(70, 65, 66, 0.95)' },
    { color:'rgba(102, 108, 60, 0.4)', shallow:'rgba(173, 184, 102, 0.95)' },
    { color:'rgba(214, 220, 228, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(190, 107, 46, 0.4)', shallow:'rgba(255, 182, 78, 0.95)' },
    { color:'rgba(46, 55, 93, 0.4)', shallow:'rgba(78, 94, 158, 0.95)' }
  ],
  tantan: [
    { color:'rgba(209, 186, 87, 0.4)', shallow:'rgba(255, 255, 148, 0.95)' },
    { color:'rgba(64, 124, 158, 0.4)', shallow:'rgba(109, 211, 255, 0.95)' },
    { color:'rgba(136, 38, 25, 0.4)', shallow:'rgba(231, 65, 43, 0.95)' },
    { color:'rgba(200, 150, 69, 0.4)', shallow:'rgba(255, 255, 117, 0.95)' },
    { color:'rgba(60, 82, 175, 0.4)', shallow:'rgba(102, 139, 255, 0.95)' },
    { color:'rgba(235, 233, 104, 0.4)', shallow:'rgba(255, 255, 177, 0.95)' },
    { color:'rgba(203, 117, 160, 0.4)', shallow:'rgba(255, 199, 255, 0.95)' },
    { color:'rgba(34, 26, 21, 0.4)', shallow:'rgba(58, 44, 36, 0.95)' }
  ],
  toonlink: [
    { color:'rgba(99, 132, 75, 0.4)', shallow:'rgba(168, 224, 128, 0.95)' },
    { color:'rgba(150, 40, 42, 0.4)', shallow:'rgba(255, 68, 71, 0.95)' },
    { color:'rgba(97, 110, 169, 0.4)', shallow:'rgba(165, 187, 255, 0.95)' },
    { color:'rgba(145, 94, 162, 0.4)', shallow:'rgba(247, 160, 255, 0.95)' },
    { color:'rgba(72, 90, 39, 0.4)', shallow:'rgba(122, 153, 66, 0.95)' },
    { color:'rgba(41, 38, 39, 0.4)', shallow:'rgba(70, 65, 66, 0.95)' },
    { color:'rgba(109, 117, 134, 0.4)', shallow:'rgba(185, 199, 228, 0.95)' },
    { color:'rgba(37, 65, 53, 0.4)', shallow:'rgba(63, 111, 90, 0.95)' }
  ],
  trail: [
    { color:'rgba(210, 205, 208, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(98, 91, 99, 0.4)', shallow:'rgba(167, 155, 168, 0.95)' },
    { color:'rgba(174, 72, 59, 0.4)', shallow:'rgba(255, 122, 100, 0.95)' },
    { color:'rgba(37, 28, 32, 0.4)', shallow:'rgba(63, 48, 54, 0.95)' },
    { color:'rgba(100, 100, 100, 0.4)', shallow:'rgba(170, 170, 170, 0.95)' },
    { color:'rgba(173, 33, 17, 0.4)', shallow:'rgba(255, 56, 29, 0.95)' },
    { color:'rgba(91, 101, 201, 0.4)', shallow:'rgba(155, 172, 255, 0.95)' },
    { color:'rgba(108, 104, 120, 0.4)', shallow:'rgba(184, 177, 204, 0.95)' }
  ],
  wario: [
    { color:'rgba(113, 125, 143, 0.4)', shallow:'rgba(192, 213, 243, 0.95)' },
    { color:'rgba(216, 185, 18, 0.4)', shallow:'rgba(255, 255, 31, 0.95)' },
    { color:'rgba(39, 37, 54, 0.4)', shallow:'rgba(66, 63, 92, 0.95)' },
    { color:'rgba(166, 45, 52, 0.4)', shallow:'rgba(255, 77, 88, 0.95)' },
    { color:'rgba(70, 121, 87, 0.4)', shallow:'rgba(119, 206, 148, 0.95)' },
    { color:'rgba(55, 110, 133, 0.4)', shallow:'rgba(94, 187, 226, 0.95)' },
    { color:'rgba(119, 131, 183, 0.4)', shallow:'rgba(202, 223, 255, 0.95)' },
    { color:'rgba(56, 75, 29, 0.4)', shallow:'rgba(95, 128, 49, 0.95)' }
  ],
  wiifit: [
    { color:'rgba(102, 185, 211, 0.4)', shallow:'rgba(173, 255, 255, 0.95)' },
    { color:'rgba(40, 57, 71, 0.4)', shallow:'rgba(68, 97, 121, 0.95)' },
    { color:'rgba(117, 151, 95, 0.4)', shallow:'rgba(199, 255, 162, 0.95)' },
    { color:'rgba(123, 145, 103, 0.4)', shallow:'rgba(209, 247, 175, 0.95)' },
    { color:'rgba(146, 73, 83, 0.4)', shallow:'rgba(248, 124, 141, 0.95)' },
    { color:'rgba(113, 35, 44, 0.4)', shallow:'rgba(192, 60, 75, 0.95)' },
    { color:'rgba(182, 168, 101, 0.4)', shallow:'rgba(255, 255, 172, 0.95)' },
    { color:'rgba(162, 145, 78, 0.4)', shallow:'rgba(255, 247, 133, 0.95)' }
  ],
  wolf: [
    { color:'rgba(66, 58, 96, 0.4)', shallow:'rgba(112, 99, 163, 0.95)' },
    { color:'rgba(107, 80, 67, 0.4)', shallow:'rgba(182, 136, 114, 0.95)' },
    { color:'rgba(77, 49, 50, 0.4)', shallow:'rgba(131, 83, 85, 0.95)' },
    { color:'rgba(82, 122, 146, 0.4)', shallow:'rgba(139, 207, 248, 0.95)' },
    { color:'rgba(95, 127, 109, 0.4)', shallow:'rgba(162, 216, 185, 0.95)' },
    { color:'rgba(185, 86, 30, 0.4)', shallow:'rgba(255, 146, 51, 0.95)' },
    { color:'rgba(36, 30, 29, 0.4)', shallow:'rgba(61, 51, 49, 0.95)' },
    { color:'rgba(122, 87, 104, 0.4)', shallow:'rgba(207, 148, 177, 0.95)' }
  ],
  yoshi: [
    { color:'rgba(104, 183, 81, 0.4)', shallow:'rgba(177, 255, 138, 0.95)' },
    { color:'rgba(188, 61, 84, 0.4)', shallow:'rgba(255, 104, 143, 0.95)' },
    { color:'rgba(86, 90, 157, 0.4)', shallow:'rgba(146, 153, 255, 0.95)' },
    { color:'rgba(229, 215, 68, 0.4)', shallow:'rgba(255, 255, 116, 0.95)' },
    { color:'rgba(238, 145, 183, 0.4)', shallow:'rgba(255, 247, 255, 0.95)' },
    { color:'rgba(75, 197, 236, 0.4)', shallow:'rgba(128, 255, 255, 0.95)' },
    { color:'rgba(196, 113, 220, 0.4)', shallow:'rgba(255, 192, 255, 0.95)' },
    { color:'rgba(103, 194, 21, 0.4)', shallow:'rgba(175, 255, 36, 0.95)' }
  ],
  younglink: [
    { color:'rgba(82, 138, 72, 0.4)', shallow:'rgba(139, 235, 122, 0.95)' },
    { color:'rgba(106, 13, 17, 0.4)', shallow:'rgba(180, 22, 29, 0.95)' },
    { color:'rgba(20, 14, 43, 0.4)', shallow:'rgba(34, 24, 73, 0.95)' },
    { color:'rgba(121, 116, 143, 0.4)', shallow:'rgba(206, 197, 243, 0.95)' },
    { color:'rgba(223, 132, 138, 0.4)', shallow:'rgba(255, 224, 235, 0.95)' },
    { color:'rgba(77, 135, 160, 0.4)', shallow:'rgba(131, 230, 255, 0.95)' },
    { color:'rgba(178, 102, 48, 0.4)', shallow:'rgba(255, 173, 82, 0.95)' },
    { color:'rgba(47, 42, 44, 0.4)', shallow:'rgba(80, 71, 75, 0.95)' }
  ],
  zelda: [
    { color:'rgba(204, 154, 178, 0.4)', shallow:'rgba(255, 255, 255, 0.95)' },
    { color:'rgba(117, 38, 19, 0.4)', shallow:'rgba(199, 65, 32, 0.95)' },
    { color:'rgba(84, 105, 164, 0.4)', shallow:'rgba(143, 179, 255, 0.95)' },
    { color:'rgba(123, 41, 77, 0.4)', shallow:'rgba(209, 70, 131, 0.95)' },
    { color:'rgba(85, 109, 68, 0.4)', shallow:'rgba(145, 185, 116, 0.95)' },
    { color:'rgba(47, 31, 48, 0.4)', shallow:'rgba(80, 53, 82, 0.95)' },
    { color:'rgba(105, 44, 57, 0.4)', shallow:'rgba(179, 75, 97, 0.95)' },
    { color:'rgba(138, 145, 186, 0.4)', shallow:'rgba(235, 247, 255, 0.95)' }
  ]
}

export default bannerBackground