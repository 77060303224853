import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Mock
require('./mock')

// router
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// i18n
import i18n from '@/i18n'

// Data
import data from '@/data'
Vue.prototype.$commonData = data

Vue.config.productionTip = false

// antd
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)
import { Form } from 'ant-design-vue';
Vue.component(Form.name, Form);
Vue.component(Form.Item.name, Form.Item);

// vchart
import VCharts from 'v-charts'
Vue.use(VCharts)

// 全局注册资源
import CustomIcon from '@/resource/CustomIcon/CustomIcon.vue'
import StockIcon from '@/resource/StockIcon/StockIcon.vue'
import BigIcon from '@/resource/BigIcon/BigIcon.vue'
import WideIcon from '@/resource/WideIcon/WideIcon.vue'
import NationFlag from '@/resource/NationFlag/NationFlag.vue'
import CustomTitle from '@/components/CustomTitle/CustomTitle'
import SquareIcon from '@/resource/SquareIcon/SquareIcon.vue'
import StageImg from '@/resource/StageImg/StageImg.vue'
import DiamondIcon from '@/resource/DiamondIcon/DiamondIcon.vue'

Vue.component('custom-icon', CustomIcon)
Vue.component('stock-icon', StockIcon)
Vue.component('big-icon', BigIcon)
Vue.component('wide-icon', WideIcon)
Vue.component('nation-flag', NationFlag)
Vue.component('custom-title', CustomTitle)
Vue.component('square-icon', SquareIcon)
Vue.component('stage-img', StageImg)
Vue.component('diamond-icon', DiamondIcon)

// 全局注册组件
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue'

Vue.component('breadcrumb', Breadcrumb)

import './registerServiceWorker'

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')