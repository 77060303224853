const gzRanking = [
  {
    gamerTag: 'Kuroko',
    mainCharacter: {
      name: 'dolly',
      color: 4
    }
  },
  {
    gamerTag: 'yooo冰',
    mainCharacter: {
      name: 'zelda',
      color: 4,
    }
  },
  {
    gamerTag: '111',
    mainCharacter: {
      name: 'reflet',
      color: 7,
    }
  },
  {
    gamerTag: '小明',
    mainCharacter: {
      name: 'donkey',
      color: 1,
    }
  },
  {
    gamerTag: 'Nero',
    mainCharacter: {
      name: 'eflame',
      color: 6,
    }
  },
  {
    gamerTag: 'Yrden',
    mainCharacter: {
      name: 'roy',
      color: 3,
    }
  },
  {
    gamerTag: 'Amaki',
    mainCharacter: {
      name: 'younglink',
      color: 5,
    }
  },
  {
    gamerTag: 'BeGINner',
    mainCharacter: {
      name: 'gamewatch',
      color: 5,
    }
  },
  {
    gamerTag: 'Sider',
    mainCharacter: {
      name: 'robot',
      color: 0,
    }
  },
  {
    gamerTag: 'Long',
    mainCharacter: {
      name: 'mario',
      color: 3,
    }
  }
]

const xmRanking = [
  {
    gamerTag: 'ASH',
    mainCharacter: {
      name: 'link',
      color: 1
    }
  },
  {
    gamerTag: 'PppZz',
    mainCharacter: {
      name: 'gekkouga',
      color: 4
    }
  },
  {
    gamerTag: 'Mockingb1rd-',
    mainCharacter: {
      name: 'chrom',
      color: 4
    }
  },
  {
    gamerTag: 'TSG',
    mainCharacter: {
      name: 'pikachu',
      color: 0
    }
  },
  {
    gamerTag: 'Fairley',
    mainCharacter: {
      name: 'dolly',
      color: 0
    }
  },
  {
    gamerTag: 'Mitey',
    mainCharacter: {
      name: 'peach',
      color: 5
    }
  },
  {
    gamerTag: 'Jason',
    mainCharacter: {
      name: 'link',
      color: 2
    }
  },
  {
    gamerTag: 'B',
    mainCharacter: {
      name: 'zelda',
      color: 1
    }
  },
  {
    gamerTag: 'UP&XG',
    mainCharacter: {
      name: 'kirby',
      color: 0
    }
  },
  {
    gamerTag: 'T1',
    mainCharacter: {
      name: 'koopa',
      color: 0
    }
  }
]

const szRanking = [
  {
    gamerTag: 'BananaSlam',
    mainCharacter: {
      name: 'kirby',
      color: 1
    }
  },
  {
    gamerTag: 'AnotherGuy',
    mainCharacter: {
      name: 'packun',
      color: 7
    }
  },
  {
    gamerTag: 'happy',
    mainCharacter: {
      name: 'richter',
      color: 1
    }
  },
  {
    gamerTag: 'xPx',
    mainCharacter: {
      name: 'snake',
      color: 0
    }
  },
  {
    gamerTag: 'Skye',
    mainCharacter: {
      name: 'edge',
      color: 0
    }
  },
  {
    gamerTag: 'Dos',
    mainCharacter: {
      name: 'yoshi',
      color: 7
    }
  },
  {
    gamerTag: 'Murasame',
    mainCharacter: {
      name: 'tantan',
      color: 6
    }
  },
  {
    gamerTag: 'Roku',
    mainCharacter: {
      name: 'wolf',
      color: 3
    }
  },
  {
    gamerTag: 'Oc',
    mainCharacter: {
      name: 'koopa',
      color: 4
    }
  },
  {
    gamerTag: 'GzStar17',
    mainCharacter: {
      name: 'shizue',
      color: 7
    }
  }
]


export default {
  'get|http://mock.test/ranking/simple/1': gzRanking,
  'get|http://mock.test/ranking/simple/2': xmRanking,
  'get|http://mock.test/ranking/simple/4': szRanking
}