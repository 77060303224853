const zh = {

  // system
  betainfo: 'CSPR2.0当前仅为测试版，数据仅供参考',

  // header
  CSPRSystem: '大乱斗积分系统',
  about: '关于',

  // index
  index: '首页',
  recentEvent: '近期比赛结果',
  scheduel: '日程',
  article: '文章',
  video: '视频',
  ranking: '排名',
  entrantCount: '人参加',
  noEvent: '本日无比赛/活动',
  sundayShort: '日',
  mondayShort: '一',
  tuesdayShort: '二',
  wednesdayShort: '三',
  thursdayShort: '四',
  fridayShort: '五',
  saturdayShort: '六',

  // player list
  playerList: '玩家列表',
  keyword: '关键字',
  characterPick: '使用斗士',
  region: '地区',
  search: '搜索',
  playerInfo: '玩家信息',
  CSPRPoint: 'CSPR积分',
  playerStats: '记录数据',
  lastTournament: '最近一次比赛',
  tournamentCount: '场比赛',
  setCount: '个对局',
  gameCount: '个小局',
  noTournamentData: '暂无比赛数据',

  // player detail
  characterStageData: '角色/场地数据',
  usingRate: '使用率',
  win: '胜利总数',
  lose: '失败总数',
  solo: '单独使用局数',
  starter: '开局选择次数',
  counter: '反制选择次数',
  drop: '局中换角次数',
  mostPlayed: '最多对局',
  mostWin: '最高胜率',
  mostLose: '最低胜率',
  fighterData: '斗士数据',
  stageData: '场地数据',
  noCharacterData: '没有选手使用角色信息',
  tournamentData: '比赛数据',
  CSPRData: 'CSPR数据',
  relatedVideo: '相关视频',
  date: '日期',
  tournament: '比赛',
  standing: '排名',
  entrants: '人数',
  more: '更多',
  seasonSelect: '选择赛季：',
  set: '对局',
  game: '小分',
  regionRank: '地区排名',
  csprRank: '大赛排名',
  tournamentTypeSelect: '比赛类型：',
  regionTournament: '地区比赛',
  bigTournament: '大型比赛',
  unranked: '无排名',

  // tournament list
  tournamentList: '比赛列表',
  CSPRRank: '分级',
  result: '结果',
  tournamentNotStart: '比赛未开始',
  tournamentRunning: '比赛进行中',

  // tournament detail
  tournamentDetail: '比赛详情',
  player: '选手',
  setStats: '大局数据',
  losingTo: '败北对手',

  // footer
  developer: '开发者',

  // character
  mario: '马力欧',
  donkey: '森喜刚',
  link: '林克',
  samus: '萨姆斯',
  samusd: '黑暗萨姆斯',
  yoshi: '耀西',
  kirby: '卡比',
  fox: '火狐',
  pikachu: '皮卡丘',
  luigi: '路易吉',
  ness: '奈斯',
  captain: '飞隼队长',
  purin: '胖丁',
  peach: '桃花公主',
  daisy: '菊花公主',
  koopa: '酷霸王',
  ice_climber: '翻越冰山者',
  sheik: '希克',
  zelda: '塞尔达',
  mariod: '马力欧医生',
  pichu: '皮丘',
  falco: '佛克',
  marth: '马尔斯',
  lucina: '露琪娜',
  younglink: '幼年林克',
  ganon: '盖侬多夫',
  mewtwo: '超梦',
  roy: '罗伊',
  chrom: '库洛姆',
  gamewatch: 'Mr. Game & Watch',
  metaknight: '魅塔骑士',
  pit: '彼特',
  pitb: '黑暗彼特',
  szerosuit: '零装甲萨姆斯',
  wario: '瓦力欧',
  snake: 'Snake',
  ike: '艾克',
  ptrainer: '宝可梦训练家',
  diddy: '迪迪刚',
  lucas: '卢卡斯',
  sonic: '索尼克',
  dedede: '帝帝帝大王',
  lucario: '路卡利欧',
  robot: '机器人',
  toonlink: '卡通林克',
  wolf: '沃鲁夫',
  murabito: '村民',
  rockman: '洛克人',
  wiifit: 'Wii Fit教练',
  rosetta: '罗莎塔&琪琪',
  littlemac: '小麦克',
  gekkouga: '甲贺忍蛙',
  palutena: '帕露蒂娜',
  pacman: '吃豆人',
  reflet: '鲁弗莱',
  shulk: '修尔克',
  koopajr: '酷霸王Jr.',
  duckhunt: '打猎',
  ryu: '隆',
  ken: '肯',
  cloud: '克劳德',
  kamui: '神威',
  bayonetta: 'Bayonetta',
  inkling: 'Inkling',
  ridley: '利德雷',
  simon: 'Simon',
  richter: 'Richter',
  krool: '库鲁鲁王',
  shizue: '西施惠',
  gaogaen: '炽焰咆哮虎',
  packun: '吞食花',
  jack: 'Joker',
  brave: '勇者',
  buddy: '阿邦&阿卡',
  dolly: '特瑞',
  master: '贝雷特/贝雷丝',
  tantan: '面面',
  pickel: '史蒂夫/艾莉克斯',
  edge: '萨菲罗斯',
  eflame: '焰/光',
  demon: '一八',
  trail: '索拉',
  miifighter: 'Mii 格斗类型',
  miiswordsman: 'Mii 剑术类型',
  miigunner: 'Mii 射击类型'
}

export default zh;